import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

import AdminNotificationsComponent from "./componentsCustomerService/columnsTables/AdminNotificationsComponent";
import ReporteDelegacion from "./componentsReports/ReporteDelegacion";
import MisPichosOrdersSuspicious from "./componentsReports/MisPichosOrdersSuspicious";
import { ReportTS } from "./componentsReports/ReportTypescript.tsx";



// import CombinedProducts from "./componentsComercialPanel/CombinedProducts";


// Layout Types
const Lienzo = lazy(() => import('./layouts/Lienzo'));
const DefaultLayout = lazy(() => import('./layouts/Default'));
const Loading = lazy(() => import('./layouts/Loading'));
const OrdersPinedLayout = lazy(() => import('./layouts/OrdersPinedLayout'));

const Login = lazy(() => import('./components/components-overview/Login'));

const LoadingPage = lazy(() => import('./views/LoadingPage'));
const LogoutPage = lazy(() => import('./views/LogoutPage'));

const DashboardAtencion = lazy(() => import('./views/DashboardAtencion'));
const DashboardAtencionRolProblema = lazy(() => import('./views/DashboardAtencionRolProblema'));
const Usuarios = lazy(() => import('./views/Usuarios'));
const ShowContent = lazy(() => import('./componentsRoles/ShowRoles&Permissions'));
const Compras = lazy(() => import('./views/Compras'));
const ListOrders = lazy(() => import('./components/dashboard-elements/ListOrders'));
const Productos = lazy(() => import('./views/Productos'));
const Catalogo = lazy(() => import('./componentsCatalogue/Catalogue'));
const Inventario = lazy(() => import('./componentsInventory/InventoryChange'));
const MisRemitos = lazy(() => import('./componentsInventory/MyDeliveryNotes'));
const ProductosMP = lazy(() => import('./views/ProductosMP'));
const Diasespeciales = lazy(() => import('./views/Diasespeciales'));
const PetshopNotificationsComponent = lazy(() => import('./componentsPetshop/PetshopNotificationsComponent'));
const Petshops = lazy(() => import('./views/Petshops'));
const PetshopFacturacion = lazy(() => import('./componentsAccountManager/FacturacioPetshop'));
const PetshopFacturacionIngresos = lazy(() => import('./componentsAccountManager/FacturacioPetshopIngresos'));
const FijarPrecios = lazy(() => import('./components/common/pricing/FijarPrecios'));
const MatchProducto = lazy(() => import('./components/common/pricing/MatchProducto'))
const AdminEmails = lazy(() => import('./components/component-email/Admin_email'));
const ListNotifications = lazy(() => import('./components/common/notifications/ListNotifications'));
const Configuraciones = lazy(() => import('./componentsConfigs/configs'));
const GestionRazas = lazy(() => import('./componentsAnimals/GestionRazas'));
const AssignmentProcess = lazy(() => import('./componentsAssignment/AssignmentProcess'));

//Marketing manager
const Landings = lazy(() => import('./componentsMarketingManager/Landings'));

//Account manager
const PetshopPays = lazy(() => import('./componentsAccountManager/PetshopPays'));
const PetshopsDetails = lazy(() => import('./componentsAccountManager/PetshopsDetails'));

// --- Components Petshop

const PetshopAcount = lazy(() => import('./componentsPetshop/PetshopAcount'));
const PetshopAccountRegistry = lazy(() => import('./componentsAccountManager/PetshopAccountRegistry'));
const PetshopRegistration = lazy(() => import('./componentsPetshop/PetshopRegistration'));
const PetshopEdit = lazy(() => import('./componentsPetshop/PetshopEdit'));
const PetshopOrders = lazy(() => import('./componentsPetshop/PetshopOrders'));
const PetshopReturnOrders = lazy(() => import('./componentsPetshop/PetshopReturnOrders'));
const PetshopReportStatics = lazy(() => import('./componentsPetshop/PetshopReportStatics'));
const PetshopDeliveryTime = lazy(() => import('./componentsPetshop/PetshopDeliveryTime'));
const PetshopNews = lazy(() => import('./componentsPetshop/PetshopNews'));
const PetshopPaying = lazy(() => import('./componentsPetshop/PetshopPaying'));
const PetshopZone = lazy(() => import('./componentsPetshop/PetshopZone'));
const PetshopDashboard = lazy(() => import('./views/PetshopDashboard'));
const PetshopTransporters = lazy(() => import('./componentsPetshop/Transporters'));
const LogisticControlPetshop = lazy(() => import("./componentsPetshop/LogisticControl/LogisticControl"));
const LogisticAssignment = lazy(() => import("./componentsPetshop/Logistic/Assignment/Assignment"));
const OrdersPreparation = lazy(() => import("./componentsPetshop/OrdersPreparation/OrdersPreparation"));
const OrdersPrePicking = lazy(() => import("./componentsPetshop/OrdersPreparation/OrdersPrePicking"));


//Components master
const MasterDashboard = lazy(() => import("./componentsMasterAdmin/Dashboard"));
const DashboardDelivery = lazy(() => import("./componentsMasterAdmin/DashboardDelivery"));
const DashboardFullfillment = lazy(() => import("./componentsMasterAdmin/DashboardFullfillment"));


//Components Finances
const DashboardFinances = lazy(() => import('./componentsFinances/Dashboard'));
const FacturacionMensual = lazy(() => import('./componentsFinances/FacturacionMensual'));
const ProductosPrecios = lazy(() => import('./componentsFinances/ProductosPrecios'));
const ProductosPreciosAnalisis = lazy(() => import('./componentsFinances/ProductosPreciosAnalisis'));

//Components Reportes
const ReportAssumedValues = lazy(() => import("./componentsReports/ReportAssumedValues"));
const DashboardReportes = lazy(() => import('./componentsReports/Dashboard'));
const ReporteIndicadoresOferta = lazy(() => import('./componentsReports/IndicadoresOferta'));
const ReportePedidosCancelados = lazy(() => import('./componentsReports/PedidosCancelados'));
const ReporteProductosVendidos = lazy(() => import('./componentsReports/SoldProducts'));
const ReporteProductosEliminadosPosEdicion = lazy(() => import('./componentsReports/DeletedProductsPostEdition'));
const ReportePedidosConSaldoEdicion = lazy(() => import('./componentsReports/OrdersWithEditionBalance'));
const DelegateOrdersByProducts = lazy(() => import('./componentsReports/ReporteDelegacionPorProducto'));
const ReporteProductosVendidosDetallado = lazy(() => import('./componentsReports/SoldProductsDetail'));
const ReportePedidosIntervenidos = lazy(() => import('./componentsReports/IntervenedOrders'));
const ReporteInstallmentError = lazy(() => import('./componentsReports/InstallmentError'));
const HeatZone = lazy(() => import('./componentsReports/HeatZoneOrders'));
const ReporteProductosPuntoVentaPorVenta = lazy(() => import('./componentsReports/ProductosPuntoVentaPorVenta'));
const ReportePetshopsHorarios = lazy(() => import('./componentsReports/PetshopSchedule'));
const ReporteGeneralOrders = lazy(() => import('./componentsReports/GeneralOrders'));
const ReprogramedOrders = lazy(() => import('./componentsReports/ReprogramedOrders'));
const ProblemOrders = lazy(() => import('./componentsReports/ProblemOrders'));
const OrderCompleteManual = lazy(() => import('./componentsReports/OrdersCompletedManually'));
const MisPichosOrders = lazy(() => import('./componentsReports/MisPichosOrders'));
const UsersWithPaymentProblems = lazy(() => import('./componentsReports/UsersWithPaymentProblems'));
const ReportCupones = lazy(() => import('./componentsReports/ReportCupones'));
const ReportFacturacionMensual = lazy(() => import('./componentsReports/ReporteFacturacionMensual'));
const ReporteSujetosSicore = lazy(() => import('./componentsReports/ReporteSujetosSicore'));
const ReporteDiferenciaStock = lazy(() => import('./componentsReports/ReporteDiferenciaStock'));
const ReportFabricaTePaga = lazy(() => import('./componentsReports/ReportFabricaTePaga'));
const ReportComisionesDuplicadas = lazy(() => import('./componentsReports/ReportComisionesDuplicadas'));
const ReportCuotas = lazy(() => import('./componentsReports/ReportCuotas'));
const ReportControlOperativo = lazy(() => import('./componentsReports/ReportControlOperativo'));
const ReportRescheduleOrders = lazy(() => import('./componentsReports/RescheduleOrders'));
const ReportProdManualAssingment = lazy(() => import('./componentsReports/ReportManualAssignment'));
const ReportPedidosLogistica = lazy(() => import('./componentsReports/ReportPedidosLogistica'));
const ReportMovCuentaCorriente = lazy(() => import('./componentsReports/ReportMovimientosCuentaCorriente'));
const ReportIngresosPetshops = lazy(() => import('./componentsReports/ReportIngresoPetshops'));
const ReportControlDevoluciones = lazy(() => import('./componentsReports/ReportControlDevoluciones'));
const ReportPetshopCreateUpdate = lazy(() => import('./componentsReports/ReportPetshopCreateUpdate'));
const ReportOutOfHours = lazy(() => import('./componentsReports/ReportOutOfHoursDelivery'));
const ReportOrdersPackedStatus = lazy(() => import('./componentsReports/OrdersPackedStatus'));
const ReportContactedCS = lazy(() => import('./componentsReports/ReportContactedCS'));
const ReportViajesColecta = lazy(() => import('./componentsReports/ReportViajesColecta'));
const ReportProductosVendidosCant = lazy(() => import('./componentsReports/ReportProductosVendidosCant'));
const ReportDevAndSolutionOrders = lazy(() => import('./componentsReports/ReportSolutionAndReturnOrders'));
const ReportOrderCanceledByGMV = lazy(() => import('./componentsReports/ReportOrderCanceledByGMV'));
const ReportConciliadorPrisma = lazy(() => import('./componentsReports/ReportConciliadorPrisma'));
const ReportUseFetchData = lazy(() => import('./componentsReports/Ejemplo-Reporte-useFetchData'));
const PurchaseOrderSimulator = lazy(() => import('./componentsReports/PurchaseOrderSimulator.js'));
const ProductInvoiceDashboard = lazy(() => import('./componentsReports/ProductInvoiceDashboard.js'));
const ProductActiveInactive = lazy(() => import('./componentsReports/ProductActiveInactive.js'));
const DeliveryEfficiency = lazy(() => import('./componentsReports/DeliveryEfficiency.js'));

//Components Comercial
const ListProdVariation = lazy(() => import('./componentsComercialPanel/ProductVariationList'));
const MasssiveTemplates = lazy(() => import('./componentsComercialPanel/MassiveTemplates'));
const GenerateImage = lazy(() => import('./componentsComercialPanel/GenerateImage'));
const PetshopCoveragePercentage = lazy(() => import('./componentsReports/PetshopCoveragePercentage'));
const CombinedProducts = lazy(() => import('./componentsComercialPanel/CombinedProducts'));
const WebDressing = lazy(() => import('./componentsComercialPanel/WebDressing.js'));

//Components Sliders
const Sliders = lazy(() => import('./componentsSliders/Sliders'))

//const versatil = lazy(() => import('./componentsComercialPanel/VersatileTemplate'));

//Components Logistics

const CoverageArea = lazy(() => import('./componentsLogistics/CoverageArea.js'));
const Variations = lazy(() => import('./componentsLogistics/Variations'));
const LogisticaDashboard = lazy(() => import('./componentsLogisticDashboard/LogisticaDashboard'));
const ReportViajesLogistic = lazy(() => import('./componentsLogisticDashboard/ReportViajesLogistic'));
const ControlLogisticDashboard = lazy(() => import('./componentsControlLogistic/ControlLogisticDashboard'));
const PetshopsCoverageArea = lazy(() => import('./componentsLogistics/PetshopsCoverageArea'));
const PetshopsCapacity = lazy(() => import('./componentsLogistics/PetshopsCapacity'));
const LogisticControl = lazy(() => import('./componentsLogistics/LogisticControl/LogisticControl'));
const DeliveryAssignment = lazy(() => import('./componentsLogistics/DeliveryAssignment/DeliveryAssignment'));
const OrdersPreparationPanel = lazy(() => import('./componentsLogistics/PreparationOrders/OrdersPreparationPanel'));
const LogisticCapacityUsed = lazy(() => import('./componentsLogistics/LogisticCapacityUsed'));
const TransportersAssignment = lazy(() => import('./componentsLogistics/TransportersAssignment/TransportersAssignment'));

//Components Suscriptions

// :D ABM Marcas
const MarcasABM = lazy(() => import('./views/MarcasABM'));

// user cheking account
const UserChekingAccount = lazy(() => import('./componentsUserChekingAccount/UserChekingAccount'));

//Schedule manager
const ScheduleManager = lazy(() => import("./componentsScheduleManager/ScheduleManager"));


//Component ABM cuponen
const CouponsABM = lazy(() => import('./componentsCoupons/Dashboard'));
const ReferredABM = lazy(() => import('./componentsReferred/Dashboard'));
const AbmLanding = lazy(() => import('./componentsLandings/AbmLanding'));

// Anti fraude
const BlackList = lazy(() => import('./componentsSuspicius/BlackList'));


// Components TMS
const TMSAuthentication = lazy(() => import('./componentsTMS/Authentication'));
const TMSRoutesCreate = lazy(() => import('./componentsTMS/Routes/Create'));
const TMSRoutesList = lazy(() => import('./componentsTMS/Routes/List'));
const TMSRoutesShow = lazy(() => import('./componentsTMS/Routes/Show'));
const TMSOrdersList = lazy(() => import('./componentsTMS/Orders/List'));

const routes = {
  publicRoutes: [
    {
      path: "/",
      exact: true,
      layout: DefaultLayout,
      component: () => <Redirect to="/default" />
    },
    {
      path: "/login",
      layout: DefaultLayout,
      public: true,
      component: Login
    },
    {
      path: "/logout",
      layout: DefaultLayout,
      public: true,
      component: LogoutPage
    },
    {
      path: "/loading",
      layout: Loading,
      public: true,
      component: LoadingPage
    },
  ],

  routesMarketingManager: [
    {
      path: "/default",
      exact: true,
      layout: DefaultLayout,
      component: () => <Redirect to="/marketing/landing" />
    },
    {
      path: "/marketing/landing",
      layout: DefaultLayout,
      component: Landings
    },
  ],

  routesAdmin: [
    {
      title: 'Notificaciones',
      keyParent: 'notification',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      path: "/atencion-cliente/notifications",
      layout: DefaultLayout,
      title: 'Alta de Notificaciones',
      nav: true,
      parent: 'notification',
      component: ListNotifications
    },
    {
      path: "/atencion-cliente/Notificaciones",
      layout: DefaultLayout,
      title: 'Mis Notificaciones',
      nav: true,
      parent: 'notification',
      component: AdminNotificationsComponent
    },
    {
      title: 'Sistema de Precios',
      keyParent: 'precios',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      path: "/Productos",
      layout: DefaultLayout,
      title: 'Productos',
      parent: 'precios',
      nav: true,
      component: ProductosMP
    },
    {
      path: "/matchProductos/:prv_id",
      layout: DefaultLayout,
      title: 'Matchear Productos',
      parent: 'precios',
      nav: false,
      component: MatchProducto
    },
    {
      path: "/fijarPrecios",
      layout: DefaultLayout,
      title: 'Fijar Precios',
      parent: 'precios',
      nav: true,
      component: FijarPrecios
    },
    {
      path: "/usuarios",
      layout: DefaultLayout,
      title: 'Usuarios Lista',
      nav: true,
      component: Usuarios
    },
    {
      path: "/roles",
      layout: DefaultLayout,
      title: 'Lista Usuarios Roles',
      nav: false,
      component: ShowContent
    },
    {
      path: "/compras",
      layout: OrdersPinedLayout,
      title: 'Compras',
      nav: false,
      component: Compras
    },
    {
      path: "/compra/:compra_id",
      layout: DefaultLayout,
      title: 'Compras',
      nav: false,
      component: Compras
    },
    {
      path: "/asignacion",
      layout: DefaultLayout,
      title: 'Compras',
      nav: false,
      component: AssignmentProcess
    },
    {
      path: "/configuraciones",
      layout: DefaultLayout,
      title: 'Configuracion',
      nav: false,
      component: Configuraciones
    },
    {
      title: 'Reportes',
      keyParent: 'report',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      path: "/mis-pichos-orders",
      layout: DefaultLayout,
      title: 'Pedidos en MisPichos',
      nav: true,
      parent: 'report',
      component: MisPichosOrders
    },
    {
      title: 'Anti-fraudes',
      keyParent: 'suspicius',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      path: "/suspicious-list",
      layout: DefaultLayout,
      title: 'Lista de bloqueos',
      nav: true,
      parent: 'suspicius',
      component: BlackList
    },
    {
      path: "/reports/suspicious-orders",
      layout: DefaultLayout,
      title: 'Pedidos Sospechosos MisPichos',
      nav: true,
      parent: 'report',
      component: MisPichosOrdersSuspicious
    },
    {
      path: "/petshop/mails",
      layout: DefaultLayout,
      title: 'Reporte de Mails',
      nav: true,
      parent: 'report',
      component: AdminEmails
    },

    {
      title: 'Warehouse',
      keyParent: 'purchaseOrderSimulator',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      path: "/purchase-order-simulator/",
      layout: DefaultLayout,
      title: 'Simulador Órdenes de Compra',
      nav: true,
      parent: 'purchaseOrderSimulator',
      component: PurchaseOrderSimulator
    },
    {
      path: "/product-invoice/",
      layout: DefaultLayout,
      title: 'Facturación Productos',
      nav: true,
      parent: 'purchaseOrderSimulator',
      component: ProductInvoiceDashboard
    },
    {
      path: "/product-active-inactive/",
      layout: DefaultLayout,
      title: 'Productos a activar/desactivar',
      nav: true,
      parent: 'purchaseOrderSimulator',
      component: ProductActiveInactive
    },
    {
      path: "/delivery-efficiency/",
      layout: DefaultLayout,
      title: 'Reporte de Eficiencia de entrega',
      nav: true,
      parent: 'petshops',
      component: DeliveryEfficiency
    },
    {
      title: 'Panel Logistico',
      keyParent: 'logistic',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },

    {
      path: "/logistic-panel/areas",
      layout: DefaultLayout,
      title: 'Zonas de entrega',
      nav: true,
      parent: 'logistic',
      component: CoverageArea
    },
    {
      path: "/logistic-panel/variations",
      layout: DefaultLayout,
      title: 'Logistica variaciones',
      nav: true,
      parent: 'logistic',
      component: Variations
    },
    {
      path: "/logistic-panel/petshops-area",
      layout: DefaultLayout,
      title: 'Areas de Cobertura de Petshops',
      nav: true,
      parent: 'logistic',
      component: PetshopsCoverageArea
    },
    {
      path: "/logistic-panel/petshops-capacidad",
      layout: DefaultLayout,
      title: 'Capacidad logística de Petshops',
      nav: true,
      parent: 'logistic',
      component: PetshopsCapacity
    },
    {
      path: "/logistic-panel/delivery-control",
      layout: DefaultLayout,
      title: 'Control de Tráfico',
      nav: true,
      parent: 'logistic',
      component: LogisticControl
    },
    {
      path: "/logistic-panel/delivery-assignment-admin",
      layout: DefaultLayout,
      title: 'Asignación de transportistas',
      nav: true,
      parent: 'logistic',
      component: DeliveryAssignment
    },
    {
      path: "/logistic-panel/preparation-orders",
      layout: DefaultLayout,
      title: 'Preparación de pedidos',
      nav: true,
      parent: 'logistic',
      component: OrdersPreparationPanel
    },
    {
      path: "/logistic-panel/logistic-capacity-used",
      layout: DefaultLayout,
      title: 'Capacidad logística usada',
      nav: true,
      parent: 'logistic',
      component: LogisticCapacityUsed
    },
    {
      path: "/control-logistica-dashboard",
      layout: DefaultLayout,
      title: 'Control Logística',
      parent: 'logistic',
      nav: true,
      component: ControlLogisticDashboard
    },
    {
      path: "/logistic-panel/transporters-assignment",
      layout: DefaultLayout,
      title: 'Vincular Transportista con Colecta',
      nav: true,
      parent: 'logistic',
      component: TransportersAssignment
    },
    {
      path: "/comercial-panel/porcentaje-cobertura",
      layout: DefaultLayout,
      title: 'Reportes por zona dibujada',
      nav: true,
      parent: 'report',
      component: PetshopCoveragePercentage
    },
    {
      path: "/comercial-panel/webdressing",
      layout: DefaultLayout,
      title: 'Web Dressing',
      parent: 'comercial',
      nav: true,
      component: WebDressing
    },
    {
      path: "/comercial-panel/heat-zone",
      layout: DefaultLayout,
      title: 'Mapa de Calor Demanda',
      nav: true,
      parent: 'report',
      component: HeatZone
    },
    //
    {
      path: "/razas",
      layout: DefaultLayout,
      title: 'Razas',
      nav: false,
      component: GestionRazas
    },
    {
      title: 'Panel Comercial',
      keyParent: 'comercial',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      path: "/comercial-panel/productos",
      layout: DefaultLayout,
      title: 'Templates individuales',
      parent: 'comercial',
      nav: true,
      component: ListProdVariation
    },
    {
      path: "/comercial-panel/catalogo",
      layout: DefaultLayout,
      title: 'Catálogo',
      parent: 'comercial',
      keyParent: 'catalogo',
      nav: true,
      component: Catalogo
    },
    {
      path: "/comercial-panel/productos-massive",
      layout: DefaultLayout,
      title: 'Templates Masivos',
      parent: 'comercial',
      nav: true,
      component: MasssiveTemplates
    },
    {
      path: "/comercial-panel/image",
      layout: DefaultLayout,
      title: 'Panel Comercial Generate Image',
      nav: false,
      component: GenerateImage
    },
    {
      path: "/listOrders",
      layout: DefaultLayout,
      title: 'Lista Compras',
      nav: true,
      component: ListOrders
    },
    {
      path: "/dias-especiales",
      layout: DefaultLayout,
      title: 'Días especiales',
      nav: true,
      component: Diasespeciales
    },
    {
      path: "/abm-cupones",
      layout: DefaultLayout,
      title: 'Cupones Lista',
      parent: 'comercial',
      nav: true,
      component: CouponsABM
    },
    {
      path: "/abm-referidos",
      layout: DefaultLayout,
      title: 'Referidos',
      nav: true,
      component: ReferredABM
    },
    {
      path: "/cuenta-corriente-usuario",
      layout: DefaultLayout,
      title: 'Cuenta Corriente Usuario',
      nav: true,
      component: UserChekingAccount
    },
    {
      path: "/schedule-manager",
      layout: DefaultLayout,
      title: 'Gestión de horarios',
      nav: true,
      component: ScheduleManager
    },
    {
      path: "/logistica-dashboard",
      layout: DefaultLayout,
      public: false,
      component: LogisticaDashboard
    },
    {
      path: "/landing",
      layout: DefaultLayout,
      title: 'Landings',
      parent: 'comercial',
      nav: true,
      component: AbmLanding
    },
    {
      path: "/productos-combinados",
      layout: DefaultLayout,
      title: 'Productos combinados',
      parent: 'comercial',
      nav: true,
      component: CombinedProducts
    },
    {
      path: "/panel-comercial/sliders",
      layout: DefaultLayout,
      title: 'Sliders',
      parent: 'comercial',
      nav: true,
      component: Sliders
    },
    {
      path: "/master-dashboard",
      layout: DefaultLayout,
      title: 'Master Dashboard',
      nav: false,
      component: MasterDashboard
    },
    {
      path: "/delivery-master-dashboard",
      layout: DefaultLayout,
      title: 'Delivery Master Dashboard',
      nav: false,
      component: DashboardDelivery
    },
    {
      path: "/fulfillment-dashboard",
      layout: DefaultLayout,
      title: 'Fullfillment Dashboard',
      nav: false,
      component: DashboardFullfillment
    },
    {
      path: "/assumed-values-dashboard",
      layout: DefaultLayout,
      title: 'Assumed Values Dashboard',
      nav: false,
      component: ReportAssumedValues
    },
  ],

  routesOutsourcingDeliveryEntity: [
    {
      path: "/default",
      layout: DefaultLayout,
      title: 'Dashboard',
      nav: true,
      component: LogisticaDashboard
    },
    {
      path: "/reporte-viajes",
      layout: DefaultLayout,
      title: 'Reporte Viajes',
      nav: true,
      component: ReportViajesLogistic
    },
  ],

  routesAccountManager: [
    {
      path: "/default",
      exact: true,
      layout: DefaultLayout,
      component: () => <Redirect to="/dashboard-account-manager" />
    },
    // -------- Finanzas
    {
      path: "/finances",
      layout: DefaultLayout,
      title: 'Dashboard Financiero',
      nav: false,
      component: DashboardFinances
    },
    {
      path: "/finances-facturacion",
      layout: DefaultLayout,
      title: 'Facturacion Mensual',
      nav: false,
      component: FacturacionMensual
    },
    {
      path: "/finances-productos",
      layout: DefaultLayout,
      title: 'Productos Precios',
      nav: false,
      component: ProductosPrecios
    },
    {
      path: "/finances-productos-analisis",
      layout: DefaultLayout,
      title: 'Productos Precios Analisis',
      nav: false,
      component: ProductosPreciosAnalisis
    },
    // -------- Reportes
    {
      path: "/reportes",
      layout: DefaultLayout,
      title: 'Dashboard Reportes',
      nav: false,
      component: DashboardReportes
    },
    {
      path: "/reporte-pedidos-reprogramados",
      layout: DefaultLayout,
      title: 'Reporte Pedidos reprogramados',
      nav: false,
      component: ReportRescheduleOrders
    },
    {
      path: "/reportes-indicadores-oferta",
      layout: DefaultLayout,
      title: 'Reporte Indicadores Oferta',
      nav: false,
      component: ReporteIndicadoresOferta
    },
    {
      path: "/reportes-productos-vendidos",
      layout: DefaultLayout,
      title: 'Reporte Productos Vendidos',
      nav: false,
      component: ReporteProductosVendidos
    },
    {
      path: "/reportes-productos-eliminados-edicion",
      layout: DefaultLayout,
      title: 'Reporte Productos Eliminados en Edición',
      nav: false,
      component: ReporteProductosEliminadosPosEdicion
    },
    {
      path: "/reportes-productos-edicion-saldo",
      layout: DefaultLayout,
      title: 'Reporte Productos con Edición de Saldo',
      nav: false,
      component: ReportePedidosConSaldoEdicion
    },
    {
      path: "/reportes-petshops-horarios",
      layout: DefaultLayout,
      title: 'Reporte de PetshopsxHorario',
      nav: false,
      component: ReportePetshopsHorarios
    },
    {
      path: "/reportes-pedidos-generales",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Generales',
      nav: false,
      component: ReporteGeneralOrders
    },
    {
      path: "/reportes-pedidos-reprogramados",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Reprogramados',
      nav: false,
      component: ReprogramedOrders
    },
    {
      path: "/reportes-pedidos-Cancelados",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Cancelados',
      nav: false,
      component: ReportePedidosCancelados
    },
    {
      path: "/reportes-productos-vendidos-detallado",
      layout: DefaultLayout,
      title: 'Reporte Productos Vendidos Detallado',
      nav: false,
      component: ReporteProductosVendidosDetallado
    },
    {
      path: "/reportes-intervenidos",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Delegados',
      nav: false,
      component: ReportePedidosIntervenidos
    },
    {
      path: "/reportes-error-cuotas",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Error Cuotas',
      nav: false,
      component: ReporteInstallmentError
    },
    {
      path: "/report-cuotas",
      layout: DefaultLayout,
      title: 'Reporte cuotas',
      nav: false,
      component: ReportCuotas
    },
    {
      path: "/reportes-productos-puntoventa-por-venta",
      layout: DefaultLayout,
      title: 'Reporte Productos por punto de venta en base a venta',
      nav: false,
      component: ReporteProductosPuntoVentaPorVenta
    },
    {
      path: "/reportes-pedidos-problemas",
      layout: DefaultLayout,
      title: 'Reporte Pedidos en problema',
      nav: false,
      component: ProblemOrders
    },
    {
      path: "/reportes-pedidos-compl-manual",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Completados Manual',
      nav: false,
      component: OrderCompleteManual
    },
    {
      path: "/reportes-usuarios-problemas-pagos",
      layout: DefaultLayout,
      title: 'Reporte Usuarios con problemas de pago',
      nav: false,
      component: UsersWithPaymentProblems
    },
    {
      path: "/report-cupones",
      layout: DefaultLayout,
      title: 'Reporte Cupones',
      nav: false,
      component: ReportCupones
    },
    {
      path: "/report-facturacion-mensual",
      layout: DefaultLayout,
      title: 'Reporte Facturación Mensual',
      nav: false,
      component: ReportFacturacionMensual
    },
    {
      path: "/report-sujetos-sicore",
      layout: DefaultLayout,
      title: 'Reporte Sujetos SICORE',
      nav: false,
      component: ReporteSujetosSicore
    },
    {
      path: "/report-diferencias-stock",
      layout: DefaultLayout,
      title: 'Reporte diferencias stock',
      nav: false,
      component: ReporteDiferenciaStock
    },
    {
      path: "/report-control-devoluciones",
      layout: DefaultLayout,
      title: 'Reporte control de devoluciones',
      nav: false,
      component: ReportControlDevoluciones
    },
    {
      path: "/report-viajes-colecta",
      layout: DefaultLayout,
      title: 'Reporte viajes colecta',
      nav: false,
      component: ReportViajesColecta
    },
    {
      path: "/report-solutions-and-return",
      layout: DefaultLayout,
      title: 'Reporte Ordenes de solución y devolución',
      nav: false,
      component: ReportDevAndSolutionOrders
    },
    {
      path: "/report-productos-vendidos-cant",
      layout: DefaultLayout,
      title: 'Reporte productos vendidos por cantidad',
      nav: false,
      component: ReportProductosVendidosCant
    },
    {
      path: "/report-order-canceled-by-gmv",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Cancelados por GMV',
      nav: false,
      component: ReportOrderCanceledByGMV
    },
    {
      path: "/report-conciliador-prisma",
      layout: DefaultLayout,
      title: 'Reporte Conciliador Prisma',
      nav: false,
      component: ReportConciliadorPrisma
    },
    {
      path: "/ejemplo-reporte-usefetchdata",
      layout: DefaultLayout,
      title: 'Reporte useFetchData',
      nav: false,
      component: ReportUseFetchData
    },
    {
      path: "/report-ingresos-petshop",
      layout: DefaultLayout,
      title: 'Reporte Ingresos a Petshops',
      nav: false,
      component: ReportIngresosPetshops
    },
    {
      path: "/report-pedidos-logistica",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Logistica',
      nav: false,
      component: ReportPedidosLogistica
    },
    {
      path: "/report-movimientos-cuenta-corriente",
      layout: DefaultLayout,
      title: 'Reporte Movimientos Cuenta Corriente',
      nav: false,
      component: ReportMovCuentaCorriente
    },
    {
      path: "/report-control-operativo",
      layout: DefaultLayout,
      title: 'Reporte control operativo pedidos',
      nav: false,
      component: ReportControlOperativo
    },
    {
      path: "/report-prod-manual-assingment",
      layout: DefaultLayout,
      title: 'Reporte productos editados/agregados manualmente',
      nav: false,
      component: ReportProdManualAssingment
    },
    {
      path: "/report-fabrica-te-paga",
      layout: DefaultLayout,
      title: 'La fabrica te paga',
      nav: false,
      component: ReportFabricaTePaga
    },
    {
      path: "/report-comisiones-duplicadas",
      layout: DefaultLayout,
      title: 'Reporte Comisiones Duplicadas',
      nav: false,
      component: ReportComisionesDuplicadas
    },
    {
      path: "/reportes-delegacion",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Delegados',
      nav: false,
      component: ReporteDelegacion
    },
    {
      path: "/reportes-delegacion-por-producto",
      layout: DefaultLayout,
      title: 'Reporte Pedidos Delegados Por Productos',
      nav: false,
      component: DelegateOrdersByProducts
    },
    {
      path: "/reportes-contactactado-customer-service",
      layout: DefaultLayout,
      title: 'Reporte Contactados CS',
      nav: false,
      component: ReportContactedCS
    },
    {
      path: "/ts-example",
      layout: DefaultLayout,
      title: 'Reporte Ejemplo TS',
      nav: false,
      component: ReportTS
    },

    // -- reportes petshops Altas - Bajas por petshops
    {
      path: "/reportes-petshops-alta-bajas",
      layout: DefaultLayout,
      title: 'Reporte de altas, bajas, pausas por Petshops',
      nav: false,
      component: ReportPetshopCreateUpdate
    },
    {
      path: "/reportes-llegadas-fuera-de-horario",
      layout: DefaultLayout,
      title: 'Reporte de llegadas fuera de horario',
      nav: false,
      component: ReportOutOfHours
    },
    {
      path: "/reporte-estado-empaquetamiento",
      layout: DefaultLayout,
      title: 'Reporte de empaquetamiento con su estado',
      nav: false,
      component: ReportOrdersPackedStatus
    },
    // --------
    {
      path: "/dashboard-account-manager",
      layout: DefaultLayout,
      title: 'Dashboard Petshops',
      nav: true,
      component: PetshopsDetails
    },
    {
      title: 'Petshops',
      keyParent: 'petshops',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      path: "/petshopRegistration",
      layout: DefaultLayout,
      title: 'Alta de Petshops',
      nav: true,
      parent: 'petshops',
      component: PetshopRegistration
    },
    {
      path: "/petshopEdit/:id",
      layout: DefaultLayout,
      title: 'Petshop Data',
      nav: false,
      parent: 'petshops',
      component: PetshopEdit
    },
    {
      path: "/account/petshopsPays",
      layout: DefaultLayout,
      nav: true,
      parent: 'petshops',
      title: 'Petshops Transferencias',
      component: PetshopPays
    },
    {
      path: "/account/PetshopAccountRegistry",
      layout: DefaultLayout,
      nav: true,
      parent: 'petshops',
      title: 'Cuenta del Petshop',
      component: PetshopAccountRegistry
    },
  ],

  routesCustomerService: [
    {
      path: "/default",
      exact: true,
      layout: DefaultLayout,

      component: () => <Redirect to="/dashboard" />
    },

    {
      path: "/dashboard",
      layout: OrdersPinedLayout,
      title: 'Dashboard',
      nav: true,
      component: DashboardAtencion
    },
    {
      path: "/dashboard-customer",
      layout: OrdersPinedLayout,
      title: 'Dashboard Pedidos Solucionar',
      nav: false,
      component: DashboardAtencionRolProblema
    },
    {
      path: "/atencion-cliente/petshops",
      layout: DefaultLayout,
      title: 'Lista de Petshops',
      parent: 'petshops',
      nav: true,
      component: Petshops
    },
    {
      path: "/atencion-cliente/facturas-petshops",
      layout: DefaultLayout,
      title: 'Facturas Petshops Egresos',
      parent: 'petshops',
      nav: true,
      component: PetshopFacturacion
    }, {
      path: "/atencion-cliente/facturas-petshops-ingresos",
      layout: DefaultLayout,
      title: 'Facturas Petshops Ingresos',
      parent: 'petshops',
      nav: true,
      component: PetshopFacturacionIngresos
    },
    {
      path: "/marcas",
      layout: DefaultLayout,
      public: false,
      component: MarcasABM
    },

  ],

  routesPetshops: [
    {
      path: "/default",
      exact: true,
      layout: DefaultLayout,
      component: () => <Redirect to="/petshop/dashboard" />
    },
    {
      path: "/petshop/dashboard",
      layout: Lienzo,
      component: PetshopDashboard
    },
    {
      path: "/petshop/misDatos",
      layout: Lienzo,
      component: PetshopAcount
    },
    {
      path: "/petshop/Notificaciones",
      layout: Lienzo,
      title: 'Mis Notificaciones',
      nav: true,
      parent: 'notification',
      component: PetshopNotificationsComponent
    },
    {
      path: "/petshop/inventario/:prv_id",
      layout: Lienzo,
      title: 'Inventario',
      nav: true,
      component: Inventario
    },
    {
      path: "/petshop/mis-remitos",
      layout: Lienzo,
      title: 'Mis Remitos',
      nav: true,
      component: MisRemitos
    },
    {
      path: "/petshop/PetshopOrders",
      layout: Lienzo,
      component: PetshopOrders
    },
    {
      path: "/petshop/PetshopReturnOrders",
      layout: Lienzo,
      component: PetshopReturnOrders
    },
    {
      path: "/petshop/delivery-control",
      layout: Lienzo,
      component: LogisticControlPetshop
    },
    {
      path: "/petshop/delivery-assignment",
      layout: Lienzo,
      component: LogisticAssignment
    },
    {
      path: "/petshop/orders-prepicking",
      layout: Lienzo,
      component: OrdersPrePicking
    },
    {
      path: "/petshop/orders-preparation",
      layout: Lienzo,
      component: OrdersPreparation
    },
    {
      path: "/petshop/productos",
      layout: Lienzo,
      component: Productos
    },
    {
      path: "/petshop/misHorarios",
      layout: Lienzo,
      component: PetshopDeliveryTime
    },
    {
      path: "/petshop/miCobertura",
      layout: Lienzo,
      component: PetshopZone,
    },
    {
      path: "/petshop/diasEspeciales",
      layout: Lienzo,
      component: PetshopNews
    },
    {
      path: "/petshop/liquidaciones",
      layout: Lienzo,
      component: PetshopPaying
    },
    {
      path: "/petshop/reporte",
      layout: Lienzo,
      component: PetshopReportStatics
    },
    {
      path: "/petshop/transportes",
      layout: Lienzo,
      component: PetshopTransporters
    },
  ],

  routesInsurrance: [
  ],

  routesTMS: [
    {
      title: 'TMS',
      keyParent: 'tms',
      path: null,
      component: null,
      layout: null,
      nav: true,
    },
    {
      parent: 'tms',
      path: "/tms/auth",
      layout: DefaultLayout,
      title: 'Autenticación',
      nav: true,
      component: TMSAuthentication,
    },
    {
      parent: 'tms',
      path: "/tms/orders",
      layout: DefaultLayout,
      title: 'Lista de Ordenes',
      nav: true,
      component: TMSOrdersList
    },
    {
      parent: 'tms',
      path: "/tms/routes/create",
      layout: DefaultLayout,
      title: 'Creacion de Rutas',
      nav: true,
      component: TMSRoutesCreate
    },
    {
      parent: 'tms',
      path: "/tms/routes/:id",
      layout: DefaultLayout,
      title: 'Detalle de Ruta',
      nav: false,
      component: TMSRoutesShow
    },
    {
      parent: 'tms',
      path: "/tms/routes",
      layout: DefaultLayout,
      title: 'Lista de Rutas',
      nav: true,
      component: TMSRoutesList
    },
  ]
};
export default routes;
