import React, { useEffect, useState } from "react";
import { Modal, Timeline, Icon, Button, Col, Row } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import { Spinner } from "../../../components/componentsUI/Spinner";
import { MdSearch, MdSend } from "react-icons/md";

//const confirm = Modal.confirm;

export default function Notes({ compra }) {
    const [commentsPet, setComentsPet] = useState("")
    const [showNotesPet, setShowNotePet] = useState(false)
    const [loading, setLoading] = useState(false)
    const [notes, setNotes] = useState([])

    useEffect(() => {
        getNotes();
        // eslint-disable-next-line
    }, [])

    const getNotes = () => {
        var params = { com_id: compra.compra_id }
        Mispichoshelper.getNotes(params).then(response => setNotes(response.payload));
    }


    const sendDataPet = () => {
        setLoading(true);
        var params = {
            com_id: compra.compra_id,
            motivo_id: 87,
            nota: commentsPet,
            puv_id: compra.petshop_id
        }
        Mispichoshelper.postNotes(params).then(response => {
            if (response.status) {
                success(response)
            } else {
                error(response)
            }
        })
    }

    const hideModal = () => {
        setShowNotePet(false);
        setLoading(false);
        getNotes();
    }

    const success = (response) => {
        Modal.success({
            title: response.payload.title,
            content: response.payload.message,
            onOk: hideModal,
            okText: 'Entendido'
        });
    }

    const error = (response) => {
        Modal.error({
            title: response.payload.title,
            content: response.payload.message,
        });
    }



    return (
        <div>
            <Button type='primary' style={{ marginBottom: '10px' }} block onClick={() => setShowNotePet(true)}>
                Nueva nota al Petshop
            </Button>
            {notes.length > 0 &&
                <Timeline>
                    <h5 className='mb-3'>Notas al Petshop: </h5>
                    {notes.map((cada_nt, i) => {
                        if (cada_nt.event === "nota petshop") {
                            return (
                                <Timeline.Item key={i} dot={<Icon type="check-circle" style={{ fontSize: '16px' }} />} color="green">
                                    <div className='notificationBox-item alert-normal p-2'>
                                        <p>Creada el : {cada_nt.date + ' Hs'} por {cada_nt.user}</p>
                                        <h6>Mensaje: {cada_nt.value}</h6>
                                        <p>Petshop: {cada_nt.petshop_data.puv_nombre}</p>
                                    </div>
                                </Timeline.Item>
                            )
                        }
                        return cada_nt
                    })
                    }
                </Timeline>
            }

            <Modal
                title="Escribi una Nota al PetShop "
                visible={showNotesPet}
                onOk={() => setShowNotePet(false)}
                onCancel={() => setShowNotePet(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                footer={null}
            >

                <textarea
                    value={commentsPet}
                    onChange={(evt) => setComentsPet(evt.target.value)}
                    placeholder="¿Que esta pasando?"
                    style={{ width: '100%', height: '120px' }}
                />
                <Row>
                    <Col xl={8}></Col>
                    <Col xl={4} className='d-flex justify-content-end'>
                        <Button
                            loading={loading}
                            type='primary'
                            onClick={sendDataPet}
                        >
                            Enviar
                            {loading ? <Spinner style={{ marginLeft: 5 }} /> : <MdSend size={18} style={{ marginLeft: 5 }} />}
                        </Button>
                    </Col>
                </Row>
            </Modal>

        </div>
        /*
         const sendData = () => {
        setLoading(true);

        var params = {
            com_id: compra.compra_id,
            motivo_id: 86,
            nota: commentsClient,
        }
        //console.log("datos a enviar", params)
        Mispichoshelper.postNotes(params).then(response => {
            if (response.status) {
                success(response)
            } else {
                error(response)
            }
        })
    }
                        <Button type='primary' style={{ marginBottom: '10px' }} block onClick={() => this.setState({ showNotesClient: true })}>
                            Nota Cliente
                        </Button>

                        <Modal
                            title="Escribi una Nota al Cliente"
                            visible={this.state.showNotesClient}
                            onCancel={() => this.setState({ showNotesClient: false })}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <textarea className='campoTextarea'
                                value={this.state.commentsClient}
                                onChange={(evt) => this.setState({ commentsClient: evt.target.value })}
                                placeholder="¿Que esta pasando?"
                            />
                            <br /><br />
                            <Button loading={this.state.loading} type='primary' onClick={this.sendData}>Enviar</Button>
                        </Modal>
                                    <Modal
                                        title="Notas del Pedido"
                                        visible={this.state.showNotes}
                                        onOk={() => this.setState({ showNotes: false })}
                                        onCancel={() => this.setState({ showNotes: false })}
                                        cancelButtonProps={{ style: { display: 'none' } }}
                                    >
                                        this.state.notas.length > 0 ?
                                            <Timeline>
                                                <h5>Notas al Cliente: </h5>
                                                {this.state.notas.map((cada_nt, i) => {
                                                    if (cada_nt.event === "nota cliente") {
                                                        return (
                                                            <Timeline.Item key={i} dot={<Icon type="check-circle" style={{ fontSize: '16px' }} />} color="green">
                                                                <div className='notificationBox-item alert-normal'>
                                                                    <p>Creada el : {cada_nt.date + 'hs'} por( {cada_nt.user} )</p>
                                                                    <h6>Mensaje: {cada_nt.value}</h6>
                                                                </div>
                                                            </Timeline.Item>
                                                        )
                                                    }
                                                })
                                                }
                                            </Timeline>
                                            :
                                            <Text>No hay notas internas en este pedido</Text>
                                            
                                        <Text></Text>
                                        {this.state.notas.length > 0 &&
                                            <Timeline>
                                                <h5>Notas al Petshop: </h5>
                                                {this.state.notas.map((cada_nt, i) => {
                                                    if (cada_nt.event === "nota petshop") {
                                                        return (
                                                            <Timeline.Item key={i} dot={<Icon type="check-circle" style={{ fontSize: '16px' }} />} color="green">
                                                                <div className='notificationBox-item alert-normal'>
                                                                    <p>Creada el : {cada_nt.date + ' Hs'} por {cada_nt.user}</p>
                                                                    <h6>Mensaje: {cada_nt.value}</h6>
                                                                    <p>Petshop: {cada_nt.petshop_data.puv_nombre}</p>
                                                                </div>
                                                            </Timeline.Item>
                                                        )
                                                    }
                                                    return cada_nt
                                                })
                                                }
                                            </Timeline>
                                        }
                                    </Modal>
                                */
    )

}

