import React, { useState } from "react";
import { Modal, Row, Col, Input } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";


export default function ManualPaid({ compra, refrescarCompra, hideManualPaid, manualPaid }) {
    const [loading, setLoading] = useState(false)
    const [paymentId, setPayment] = useState("")
    const [coments, setComents] = useState("")


    const sendPayment = () => {
        if (!paymentId.length) {
            alert('Debe completar un payment ID');
            return;
        }
        if (!coments.length) {
            alert('Debe completar con observaciones');
            return;
        }
        setLoading(true)
        Mispichoshelper.postManualPaid({ payment_id: paymentId, observaciones: coments, com_id: compra.compra_id }).then(informaResponse);
    }

    const informaResponse = (response) => {
        setLoading(false)
        if (response && response.payload !== undefined) {
            if (response.payload.status === "success") {
                success(response, refrescarCompra, hideManualPaid, compra);
            } else {
                error(response);
            }
        } else {
            console.log('fallamos sin respuesta compra medio de pago');
        }
    }

    return (
        <Modal
            title="Pago manual"
            visible={manualPaid}
            onOk={() => Modal.confirm({
                title: 'Estás por marcar como pagada una compra.',
                content: 'Recordá que este proceso tiene impacto en el proceso del pedido.',
                onOk: () => sendPayment()
            })}
            okText={loading ? "Cargando..." : "Pagar manualmente"}
            onCancel={() => hideManualPaid('manualPaid', false)}
        >
            <div>
                <Row>
                    <Col md="4" className="form-group">
                        <label htmlFor="feDomicilioPiso">Payment id</label>
                        <Input
                            type="text"
                            value={paymentId}
                            onChange={(e) => setPayment(e.target.value)}
                        />
                    </Col>
                    <Col md="8" className="form-group">
                        <label htmlFor="feDomicilioPiso">Observaciones</label>
                        <Input
                            type="text"
                            value={coments}
                            onChange={(e) => setComents(e.target.value)}
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}


function success(response, refrescarCompra, hideManualPaid, compra) {
    refrescarCompra(compra.compra_id);
    Modal.success({
        title: response.payload.title,
        content: response.payload.message,
        onOk: () => hideManualPaid('manualPaid', false),
        okText: 'Entendido'
    });
}

function error(response) {
    Modal.error({
        title: response.payload.title,
        content: response.payload.message,
    });
}

