import React, { useEffect, useState } from "react";
import { Button, Tag, Modal, Radio } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import EditProductsByItem from "./EditProductsByItem";

const confirm = Modal.confirm;

export default function EditProducts({ compra, editaCantidadProducto, toggleModal, editarCompra, refrescarCompra }) {

    const [orderPrice, setOrderPrice] = useState(compra.compra_total);
    const [diffPrice, setDiffPrice] = useState(null);
    const [saldo, setSaldo] = useState(0);
    const [mesage, setMesage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [obs, setObs] = useState(null);
    const [responsability, setRespon] = useState(null);


    useEffect(() => {
        calculaTotal();
        // eslint-disable-next-line
    }, [compra]);

    const calculaTotal = () => {
        let total = 0;
        for (const [index, value] of compra.productos.entries()) {
            total = total + value.cor_precio * (value.cor_cantidad_nueva !== undefined ? value.cor_cantidad_nueva : value.cor_cantidad);
        }
        for (const [index, value] of compra.productos_combinados.entries()) {
            total = total + value.cor_precio * (value.cor_cantidad_nueva !== undefined ? value.cor_cantidad_nueva : value.cor_cantidad);
        }
        total = total - compra.compra_credito - compra.compra_descuento
        let valor_saldo = compra.compra_medio_pago !== "efectivo" ? total - compra.compra_total : total;
        let aux = valor_saldo > 0 ? '(a pagar por el cliente)' : (valor_saldo < 0 ? '(a reintegrar al cliente)' : '');
        let difTotal = total - compra.compra_total;
        setSaldo(valor_saldo)
        setOrderPrice(total)
        setMesage(aux)
        setDiffPrice(difTotal)
    }



    const sendProductEdition = () => {
        if (!responsability) {
            Modal.error({
                title: 'Informacion!',
                content: 'Tenes que elegir una responsabilidad',
            });
            return
        }
        if (!obs) {
            Modal.error({
                title: 'Informacion!',
                content: 'El campo de observaciones es obligatorio',
            });
            return
        }
        setShowConfirm(false)
        setLoading(true)
        Mispichoshelper.postEditarProductosCompra({
            productos: compra.productos,
            productos_combinados: compra.productos_combinados,
            compra_id: compra.compra_id,
            observaciones: obs,
            motivo: responsability
        }).then(informaResponse);
    }



    const informaResponse = (response) => {
        refrescarCompra(compra.compra_id);
        setLoading(false)
        if (response.payload.status) {
            success(response);
        } else {
            error(response);
        }
    }

    const success = (response) => {
        Modal.success({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => window.location.reload(),
            okText: 'Entendido'
        });
    }

    const error = (response) => {
        Modal.error({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => window.location.reload(),
        });
    }

    return (
        <Modal
            title="Editar compra"
            style={{ minWidth: '50vw' }}
            visible={editarCompra}
            onOk={() => setShowConfirm(true)}
            onCancel={() => {
                toggleModal('editarCompra', false)
            }}
            footer={[
                <Button key="back" onClick={() => window.location.reload()}>
                    Volver
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={() => setShowConfirm(true)}>
                    Editar productos
                </Button>,
            ]}
        >
            <div className="aux">
                <Button type="primary" onClick={async () => {
                    await toggleModal('buscarProducto', true)
                }}
                    style={{ lineHeight: '0' }}> Agrega mas</Button>
                <br />
                <br />
                <div className='w-100 border-bottom m-0 pr-3 text-right'>
                    Sub Total
                </div>
                {compra.productos.map((item, i) => <EditProductsByItem key={i} editaCantidadProducto={editaCantidadProducto} indiceProducto={i} calculaTotal={calculaTotal} producto={item} />)}
                {compra.productos_combinados.map((item, i) => <EditProductsByItem key={i} editaCantidadProducto={editaCantidadProducto} indiceProducto={i} calculaTotal={calculaTotal} producto={item} />)}
                <article className="EditarCompraResumen">
                    <p style={{ marginRight: "4%" }}>
                        Medio de pago:  {compra.compra_medio_pago}
                    </p>

                    <div className='d-flex justify-content-end'>
                        <div>
                            <p >
                                Valor original del pedido
                                {(compra.compra_credito || compra.compra_descuento) && (
                                    <>
                                        <br />
                                        <span style={{ fontSize: '12px', color: 'green' }} >Post descuentos</span>
                                    </>
                                )}
                            </p>
                        </div>
                        <div>
                            <Tag>${compra.compra_total} </Tag>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <div>
                            <p>
                                Nuevo valor del pedido
                                {(compra.compra_credito || compra.compra_descuento) && (
                                    <>
                                        <br />
                                        <span style={{ fontSize: '12px', color: 'green' }} >Post descuentos</span>
                                    </>
                                )}
                            </p>
                        </div>
                        <div>
                            <Tag color='grey'>${orderPrice}</Tag>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <div>
                            <p>
                                Diferencia con pedido anterior
                                {(compra.compra_credito || compra.compra_descuento) && (
                                    <>
                                        <br />
                                        <span style={{ fontSize: '12px', color: 'green' }} >Post descuentos</span>
                                    </>
                                )}
                            </p>
                        </div>
                        <div>
                            <Tag color='grey'>${diffPrice}</Tag>
                        </div>
                    </div>
                    <p>
                        Saldo resultante {mesage} <Tag className={saldo > 0 ? 'ant-tag-red' : (saldo < 0 ? 'ant-tag-green' : '')}>${saldo}</Tag>
                    </p>

                </article>
            </div>
            <SendResponsabilityAndConfirm
                compra={compra}
                showConfirm={showConfirm}
                orderPrice={orderPrice}
                setShowConfirm={setShowConfirm}
                sendProductEdition={sendProductEdition}
                setRespon={setRespon}
                setObs={setObs}
            />
        </Modal>
    )

}

const SendResponsabilityAndConfirm = ({ sendProductEdition, showConfirm, compra, orderPrice, setShowConfirm, setRespon, setObs }) => {
    let valor_saldo = orderPrice - compra.compra_total;
    let msj_saldo = valor_saldo > 0 ? ' generará un link de pago con solicitud de dinero al cliente por $' + valor_saldo : (valor_saldo < 0 ? ' hará una devolución parcial al cliente por la diferencia de $' + valor_saldo : ' no hará nada ya que el saldo nuevo es igual al original.');
    let content = compra.compra_medio_pago === "efectivo" ? 'El medio de pago es efectivo, esto no tendrá ninguna repercusión de devoluciones ni solicitudes de dinero' : 'El medio de pago es tarjeta, el sistema' + msj_saldo;

    return (
        <Modal
            title="Confirma la edición de productos?"
            style={{ minWidth: '50vw' }}
            visible={showConfirm}
            onOk={() => sendProductEdition()}
            onCancel={() => setShowConfirm(false)}
            footer={[
                <Button key="back" onClick={() => setShowConfirm(false)} > Volver</Button>,
                <Button key="submit" type="primary" onClick={() => sendProductEdition()}>
                    Si, editar productos
                </Button>,
            ]}

        >
            <p className='MB1'>{content}</p>
            <div className='MT2'>
                <Radio inline name='motivo' value={116} onChange={(e) => setRespon(parseInt(e.target.value))} >
                    <span style={{ marginRight: 8 }}>Responsabilidad petshop</span>
                </Radio>
                <Radio inline name='motivo' value={117} onChange={(e) => setRespon(parseInt(e.target.value))}>
                    <span style={{ marginRight: 8 }}>Responsabilidad interna</span>
                </Radio>
                <Radio inline name='motivo' value={115} onChange={(e) => setRespon(parseInt(e.target.value))}>
                    <span>Responsabilidad cliente</span>
                </Radio>
            </div>
            <div>
                <label>Comentario:</label>
            </div>
            <textarea rows="4" cols="50" onChange={(evt) => setObs(evt.target.value)} className="campoTextarea"></textarea>

        </Modal>
    )
}
