import React, { useState } from "react";
import { AddComment, VerComprasAnt, VerMapaMultiple, VerPerfilCliente } from "./Actions";
import { Sticker } from "./Stickers";
import moment from 'moment';
import Tag from '../../../components/componentsUI/TagList';
import { AiOutlineCalendar } from "react-icons/ai";
import { AiOutlineEnvironment } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { MdPets } from "react-icons/md";
import CopyButton from "../../../components/common/CopyButton";

export default function InfoLeftBottom({ compra, refrescarCompra }) {

  const [mapPoints, setMapPoints] = useState();
  const [mapShow, setMapShow] = useState();
  var realizadas = compra.compra_delegaciones_posibles - compra.compra_delegaciones_realizadas;
  realizadas = realizadas < 0 ? 0 : realizadas;
  var muestraComentarios = false
  if (compra.tipo_problema_full && compra.petshop_id === compra.tipo_problema_full.petshop_id) {
    muestraComentarios = true
  }

  let distance_phone = compra.petshop_consulta_telefono_full ? compra.petshop_consulta_telefono_full.data : null;

  const copyText = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    alert("Se ha copiado: " + text)
  }

  return (
    <article className='compraResumenLista'>
      {compra.petshop_id === 1 &&
        <div className="ItemResumenLista RowCenter SpaceBetween" >
          <div className='TextCenter'>Delegaron ({compra.compra_delegaciones_realizadas})</div>
          <div className='TextCenter'>Pets restantes ({realizadas})</div>
          <div className='TextCenter'>Posibilidades SH ({compra.compra_cant_petshops_posibles_no_filtra_horario})</div>
        </div>
      }
      <hr />

      <div className="RowCenter SpaceBetween ItemResumenLista">
        <div>
          <div className="RowCenter">
            <h6>Cliente:</h6>
            {compra.tag_mala_experiencia && <Tag type='danger' labelText='Mala experiencia' />}
          </div>
          <div> {compra.compra_cliente + ' (' + compra.compra_clt_mail + ')'} {<CopyButton text={compra.compra_clt_mail} />}</div>
          {compra.cliente_mascotas &&
            <div> Mascotas: {compra.cliente_mascotas} </div>
          }
        </div>
        <AiOutlineMail className='compraAccionBtn' onClick={() => window.open('https://soporte.mispichos.com/support/mispichos/ShowHomePage.do#Contacts/search/CurDep/' + compra.compra_clt_mail)} />
      </div>

      <div className="RowCenter SpaceBetween ItemResumenLista">
        <div>
          <h6>Compras:</h6>
          <span>Historial disponible.</span>
        </div>
        <VerComprasAnt compra={compra} />
      </div>

      <div className="RowCenter SpaceBetween ItemResumenLista">
        <div>
          <div className="RowCenter w100">
            <h6>Telefono:

              {compra.petshop_consulta_telefono &&
                <Tag type='danger'
                  labelText={(compra.petshop_consulta_telefono_full ? (compra.petshop_consulta_telefono_full.value ? compra.petshop_consulta_telefono_full.value : "") : "")
                    + " Consultó teléfono " + moment(compra.petshop_consulta_telefono.date).format("DD/MM/YYYY HH:mm:ss") + `${distance_phone ? ` a ${distance_phone.distance} mts de distancia` : ''}`}
                  onClick={() => {
                    if (compra.petshop_consulta_telefono_full && compra.petshop_consulta_telefono_full.latitud && compra.petshop_consulta_telefono_full.longitud) {
                      setMapShow(true)
                      setMapPoints([
                        {
                          lat: compra.petshop_consulta_telefono_full.latitud,
                          lng: compra.petshop_consulta_telefono_full.longitud,
                          title: "Consultó teléfono",
                          body: moment(compra.petshop_consulta_telefono.date).format("YYYY-MM-DD HH:mm:ss"),
                          icon: 1
                        }
                      ]

                      )
                    }
                  }}
                />
              }
            </h6>
            {compra.compra_tel_clt ? (
              (compra.compra_tel_clt.toString().length < 11 || compra.compra_tel_clt.toString().length > 13) ?
                <VerPerfilCliente cliente_email={compra.compra_clt_mail} refrescarCompra={refrescarCompra} compraId={compra.compra_id} clienteId={compra.cliente_id} />
                :
                null
            )
              :
              null
            }
          </div>

          <span>
            {compra.compra_tel_clt} {<CopyButton text={compra.compra_tel_clt} />}
          </span>

        </div>
        <VerPerfilCliente showbutton={true} cliente_email={compra.compra_clt_mail} refrescarCompra={refrescarCompra} compraId={compra.compra_id} clienteId={compra.cliente_id} />
      </div>

      <div className="RowCenter SpaceBetween ItemResumenLista">
        <div>
          <div className="RowCenter">
            <h6>Fecha de entrega</h6>
            {compra.tag_reprogramado !== 0 && compra.tag_reprogramado && <Tag type='danger' labelText='Reprogramado' />}
          </div>


          {compra.delivery_data.length > 0 && compra.delivery_data[0].delivery_start && !compra.delivery_data[0].delivery_complete && compra.delivery_data[0].deliveredStartLat &&
            <div style={{ display: "inline-block" }}
              onClick={() => {
                setMapShow(true)
                setMapPoints([
                  {
                    lat: compra.cliente_latitud,
                    lng: compra.cliente_longitud,
                    title: compra.compra_cliente ? compra.compra_cliente : "",
                    body: compra.compra_domicilio ? compra.compra_domicilio : "",
                  }, {
                    lat: compra.delivery_data[0].deliveredStartLat,
                    lng: compra.delivery_data[0].deliveredStartLng,
                    title: "En camino",
                    body: `${compra.delivery_data[0].delivery_start}`,
                    icon: 1
                  }
                ]
                )
              }}
            >
              <Sticker backColor="enCamino" nombre={`En camino ${compra.delivery_data[0].delivery_start}`} />
            </div>
          }
          {compra.delivery_data.length > 0 && compra.delivery_data[0].delivery_complete && compra.delivery_data[0].latitude_complete &&
            <div style={{ display: "inline-block" }}
              onClick={() => {
                setMapShow(true)
                setMapPoints([
                  {
                    lat: compra.cliente_latitud,
                    lng: compra.cliente_longitud,
                    title: compra.compra_cliente ? compra.compra_cliente : "",
                    body: compra.compra_domicilio ? compra.compra_domicilio : "",
                  }, {
                    lat: compra.delivery_data[0].latitude_complete,
                    lng: compra.delivery_data[0].longitude_complete,
                    title: "Entregado",
                    body: `${compra.delivery_data[0].delivery_complete} a ${compra.delivery_data[0].distance_to_client} mts del cliente`,
                    icon: 1
                  }
                ]
                )
              }}
            >
              <Sticker backColor="entregado" nombre={`Entregado ${compra.delivery_data[0].delivery_complete} a ${compra.delivery_data[0].distance_to_client} mts del cliente`} />
            </div>
          }
          {compra.tag_llega_tarde ? <Tag color='Tomato'>{compra.tag_llega_tarde_mensaje}</Tag> : null}
          <span> {compra.compra_fecha_entrega + "hs"} </span>

        </div>
        <AiOutlineCalendar className='compraAccionBtn' />
      </div>

      <div className="RowCenter SpaceBetween ItemResumenLista">
        <div>
          <div className="RowCenter">
            <h6>Pichos registrados:</h6>
          </div>
          <span>{compra.cliente_mascotas ? compra.cliente_mascotas : 'Sin datos'}</span>
        </div>
        <MdPets className='compraAccionBtn' />

      </div>

      <div className="RowCenter SpaceBetween ItemResumenLista">
        <div>
          <div className="RowCenter">
            <h6>Domicilio de entrega:</h6>
            {compra.tag_cambio_domicilio ? <Tag type='danger' labelText='Cambio domicilio' /> : null}
          </div>
          <span>  {compra.compra_domicilio} </span>
        </div>

        <AiOutlineEnvironment className='compraAccionBtn'
          onClick={() => {
            setMapShow(true)
            setMapPoints([
              {
                lat: compra.cliente_latitud,
                lng: compra.cliente_longitud,
                title: compra.compra_cliente ? compra.compra_cliente : "",
                body: compra.compra_domicilio ? compra.compra_domicilio : "",
              }
            ]
            )
          }}
        />
        <VerMapaMultiple points={mapPoints} mapShow={mapShow} setMapShow={setMapShow} />

      </div>

      {compra.compra_nota !== "" &&

        <div className="RowCenter SpaceBetween ItemResumenLista">
          <div>
            <div className="RowCenter">
              <h6>Nota del Cliente:</h6>
            </div>
            <span>{compra.compra_nota}</span>
          </div>
          < AddComment compra={compra} refrescarCompra={refrescarCompra} />
        </div>
      }
      {muestraComentarios &&
        <div className="RowCenter SpaceBetween ItemResumenLista">
          <div>
            <h6>Petshop comentarios:</h6>
            <span>{compra.tipo_problema_full.event} {compra.tipo_problema_full.value !== "" && <strong>Explicacion adicional: {compra.tipo_problema_full.value}</strong>} </span>
          </div>
        </div>
      }

    </article>
  )
}

