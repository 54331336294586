import React, { useEffect } from "react";
import InfoLeftTop from "./LeftTop";
import InfoLeftBottom from "./LeftBottom";
import InfoRight from "./InfoRight";
import UserHappines from "../../../components/compra/UserHappines";
import { Col, Row } from "antd";
import CardOliver from "../../../components/componentsUI/CardOliver";
import SocketPinnedOrders from "../../../socket/modules/PinnedOrders";

//"antd/lib/input/TextArea";

function OrderSummary({ compra, refrescarCompra, userHappiness, infoPaymentClient, domicilio, toggleInfoClientPayment, isLoading }) {

  useEffect(() => {
    SocketPinnedOrders.search_all_pinned_orders();
  }, [])

  return (
    <CardOliver>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <InfoLeftTop compra={compra} toggleInfoClientPayment={toggleInfoClientPayment} infoPaymentClient={infoPaymentClient} domicilio={domicilio} isLoading={isLoading} />
          {userHappiness &&
            <UserHappines
              badExperienceLastOrder={userHappiness.lastOrder}
              goodExperiences={userHappiness.previosOrders.cant}
              badExperiences={userHappiness.previosOrders.cant_mala_experiencia}
              cliente={'Felicidad del cliente'}
            />
          }
          <InfoLeftBottom compra={compra} refrescarCompra={refrescarCompra} />
        </Col>
        <Col span={12}>
          <InfoRight compra={compra} />
        </Col>
      </Row>
    </CardOliver>
  );
}

export default OrderSummary