import React from "react";
import { Button, Input } from 'antd';
import Autocomplete from 'react-google-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
import { MispichosVars } from "../../utils/Mispichoshelper";

// Mapa
import Leaflet from '../map/Leaflet';

class AddressAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, map: false, verCobertura: false, latitud: 0, longitud: 0, localidad: '', piso: 0, dpto: 0, partido: '', entre_calles: '', barrio: '', ciudad: '', pais: '', calle: '', altura: '', cp: '' };

  }

  showMap = () => {
    this.setState({ map: true });
  }

  hideMap = () => {
    this.setState({ map: false });
  }

  cargaCamposForm(place) {
    var es_place = false;
    console.log(place);
    this.setState({ latitud: place.geometry.location.lat(), longitud: place.geometry.location.lng() });
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      var value = place.address_components[i].short_name;
      if (addressType === "postal_code") {
        this.setState({ cp: value });
      }
      if (addressType === "street_number") {
        this.setState({ altura: value });
      }
      if (addressType === "sublocality_level_1" || addressType === "sublocality" || addressType === "political") {
        this.setState({ barrio: value });
      }
      if (addressType === "route") {
        if (!es_place) {
          this.setState({ calle: value });
        } else {
          this.setState({ calle: place.name });
        }

      }
      if (addressType === "locality") {
        this.setState({ localidad: place.name });
      }
      if (addressType === "administrative_area_level_2") {
        this.setState({ partido: value });
      }
      if (addressType === "administrative_area_level_1") {
        var pos_borrar = value.toLowerCase().indexOf("provincia de ");
        if (pos_borrar !== -1) {
          value = value.substring(13);
        }
        this.setState({ ciudad: value });
      }
      if (addressType === "country") {
        this.setState({ pais: place.address_components[i].long_name });
      }
    }

    this.props.setFatherState && this.sendState();

  }



  sendAddress = () => {
    var address = { piso: this.state.piso, cityLat: this.state.latitud, cityLng: this.state.longitud, calle: this.state.calle, altura: this.state.altura, cod_postal: this.state.cp, barrio: this.state.barrio, ciudad: this.state.ciudad, localidad: this.state.localidad, pais: this.state.pais, partido: this.state.partido, depto: this.state.dpto }
    this.props.receiveAdress(address);
  }

  sendState = () => {
    var address = { piso: this.state.piso, cityLat: this.state.latitud, cityLng: this.state.longitud, calle: this.state.calle, altura: this.state.altura, cod_postal: this.state.cp, barrio: this.state.barrio, ciudad: this.state.ciudad, localidad: this.state.localidad, pais: this.state.pais, partido: this.state.partido, depto: this.state.dpto }
    this.props.setFatherState(address);
  }

  render() {
    return (
      <div className='Card'>
        <fieldset>
          <label htmlFor="feDomicilio">Calle y Altura (No escribas el piso ni el interior.)</label>
          <Autocomplete
            onPlaceSelected={(place) => {
              this.cargaCamposForm(place);
            }}
            types={['address']}
            componentRestrictions={{ country: "ar" }}
          />
        </fieldset>
        <div className="GridTres">
          <fieldset>
            <label htmlFor="feDomicilioPiso">Calle</label>
            <Input
              type="text"
              value={this.state.calle}
              disabled
            />
          </fieldset>
          <fieldset>
            <label htmlFor="feDomicilioPiso">Altura</label>
            <Input
              type="text"
              value={this.state.altura}
              disabled
            />
          </fieldset>
          <fieldset>
            <label htmlFor="feDomicilioPiso">Barrio</label>
            <Input
              type="text"
              value={this.state.barrio}
              disabled
            />
          </fieldset>
        </div>
        <fieldset>
          <label htmlFor="feDomicilioPiso">Entre calles</label>
          <Input
            type="text"
            value={this.state.entre_calles}
            onChange={(e) => { this.setState({ entre_calles: e.target.value }, () => this.sendState()) }}
            required
          />
        </fieldset>
        <div className="GridTres">
          <fieldset>
            <label htmlFor="feDomicilioPiso">Piso</label>
            <Input
              id="feDomicilioPiso"
              type="tel"
              placeholder="numero"
              value={this.state.piso}
              onChange={(e) => { this.setState({ piso: e.target.value }, () => this.sendState()) }}
              required
            />
          </fieldset>
          <fieldset>
            <label htmlFor="feDomicilioDepartamento">Dpto</label>
            <Input
              id="feDomicilioDepartamento"
              type="text"
              value={this.state.dpto}
              onChange={(e) => { this.setState({ dpto: e.target.value }, () => this.sendState()) }}
              required
            />
          </fieldset>
          <fieldset>
            <label htmlFor="feDomicilioCP">CP:</label>
            <Input
              id="feDomicilioCP"
              type="tel"
              placeholder="0000"
              onChange={(e) => { this.setState({ cp: e.target.value }, () => this.sendState()) }}
              value={this.state.cp}
              required
            />
            {this.state.verCobertura && this.state.latitud && this.state.longitud ? <Button className='btn-outline-success btn-block mt-4' onClick={this.showMap}>Ver Cobertura</Button> : null}
          </fieldset>
        </div>
        {this.state.latitud !== 0 &&
          <fieldset>
            <div style={{ height: 200, width: "100%" }}>
              <Leaflet lat={this.state.latitud} lng={this.state.longitud} id={"mapAdressAutoComplete"} />
            </div>
          </fieldset>
        }
        <fieldset className='MT1'>
          {!this.props.setFatherState ? <Button className='btn-outline-primary btn-block' onClick={() => this.sendAddress()}>Cargar dirección</Button> : null}
        </fieldset>
      </div>
    )
  }
}
export default GoogleApiWrapper({
  apiKey: MispichosVars.GMAPS_KEY,
  libraries: ['places']
})(AddressAutoComplete);

