import React from "react";
import { Modal, Button, Row, Col } from 'antd';
import AltaUsuarioMap from './AltaUsuariosMapa';
import AddressAutoComplete from "../common/AdressAutoComplete";
import Mispichoshelper from "../../utils/Mispichoshelper";

class OrderUserAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, map: false, verCobertura: false, latitud: 0, longitud: 0, localidad: '', piso: 0, dpto: 0, partido: '', entre_calles: '', barrio: '', ciudad: '', pais: '', calle: '', altura: '', cp: '' };
    this.showMap = this.showMap.bind(this);
    console.log('show alta domicilio');
  }

  showMap() {
    this.setState({ map: true });
  }

  hideMap() {
    this.setState({ map: false });
  }

  cargaCamposForm(place) {
    var es_place = false;
    console.log(place);
    this.setState({ latitud: place.geometry.location.lat(), longitud: place.geometry.location.lng() });
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      var value = place.address_components[i].short_name;
      if (addressType === "postal_code") {
        this.setState({ cp: value });
      }
      if (addressType === "street_number") {
        this.setState({ altura: value });
      }
      if (addressType === "sublocality_level_1" || addressType === "sublocality" || addressType === "political") {
        this.setState({ barrio: value });
      }
      if (addressType === "route") {
        if (!es_place) {
          this.setState({ calle: value });
        } else {
          this.setState({ calle: place.name });
        }

      }
      if (addressType === "locality") {
        this.setState({ localidad: place.name });
      }
      if (addressType === "administrative_area_level_2") {
        this.setState({ partido: value });
      }
      if (addressType === "administrative_area_level_1") {
        var pos_borrar = value.toLowerCase().indexOf("provincia de ");
        if (pos_borrar !== -1) {
          value = value.substring(13);
        }
        this.setState({ ciudad: value });
      }
      if (addressType === "country") {
        this.setState({ pais: place.address_components[i].long_name });
      }
    }


  }

  handleOk = () => {
    this.setState({ loading: true });
    Mispichoshelper.postAltaDomicilioPerfil({ address: this.state.address, usuario_id: this.props.compra.cliente_id }).then(this.informaResponse);
  }

  informaResponse = (response) => {
    this.setState({ loading: false });
    if (response.payload.status) {
      this.success(response);
    } else {
      this.error(response);
    }
  }
  receiveAdress = (address) => {
    console.log('aca', address);
    alert('direcció cargada, ahora puede guardar domicilio');
    this.setState({ address });
  }

  success(response) {
    Modal.success({
      title: response.payload.title,
      content: response.payload.message,
      onOk: this.props.hideAltaDomicilio,
      okText: 'Entendido'
    });
  }

  error(response) {
    Modal.error({
      title: response.payload.title,
      content: response.payload.message,
      onOk: this.props.hideAltaDomicilio,
    });
  }

  setFatherState = (address) => {
    console.log('Datos de Adress en Padre: ', address);
    this.setState({ address });
  }

  render() {
    return (
      <Modal
        title="Alta domicilio"
        style={{ minWidth: '45vw' }}
        visible={this.props.showAltaDomicilio}
        onOk={this.handleOk}
        onCancel={this.props.hideAltaDomicilio}
        footer={[
          <Button className="btn-outline-danger" key="back" onClick={this.props.hideAltaDomicilio}>
            Cancelar
          </Button>,
          <Button loading={this.state.loading} className="btn-primary" onClick={() => this.handleOk()}>
            Agregar nuevo domicilio
          </Button>,
        ]}
      >
        <AddressAutoComplete setFatherState={this.setFatherState} />
        <Row form>
          <Col lg='12'>
            {this.state.latitud && this.state.longitud ?
              <AltaUsuarioMap compra={{ cliente_latitud: this.state.latitud, cliente_longitud: this.state.longitud, petshop_latitud: this.props.compra.petshop_latitud, petshop_longitud: this.props.compra.petshop_longitud }} />
              : null}
          </Col>
        </Row>
      </Modal>
    )
  }
}
export default OrderUserAddress;
