import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

// Auth
import AuthService from "./utils/AuthService";

import DashboardAtencionRolProblema from "./views/DashboardAtencionRolProblema";
import AdminNotificationsComponent from "./componentsCustomerService/columnsTables/AdminNotificationsComponent";

//Layout Types
const Lienzo = lazy(() => import('./layouts/Lienzo'));
const DefaultLayout = lazy(() => import('./layouts/Default'));
const LoginLayout = lazy(() => import('./layouts/LoginLayout'));
const Loading = lazy(() => import('./layouts/Loading'));
const OrdersPinedLayout = lazy(() => import('./layouts/OrdersPinedLayout'));

const LoginPage = lazy(() => import('./views/LoginPage'));
const LoadingPage = lazy(() => import('./views/LoadingPage'));
const LogoutPage = lazy(() => import('./views/LogoutPage'));

const DashboardAtencion = lazy(() => import('./views/DashboardAtencion'));
const Usuarios = lazy(() => import('./views/Usuarios'));
const ShowContent = lazy(() => import('./componentsRoles/ShowRoles&Permissions'));
const Compras = lazy(() => import('./views/Compras'));
const ListOrders = lazy(() => import('./components/dashboard-elements/ListOrders'));
const Productos = lazy(() => import('./views/Productos'));
const ProductosMP = lazy(() => import('./views/ProductosMP'));
const Diasespeciales = lazy(() => import('./views/Diasespeciales'));
const PetshopNotificationsComponent = lazy(() => import('./componentsPetshop/PetshopNotificationsComponent'));
const Petshops = lazy(() => import('./views/Petshops'));
const FijarPrecios = lazy(() => import('./components/common/pricing/FijarPrecios'));
const MatchProducto = lazy(() => import('./components/common/pricing/MatchProducto'))
const AdminEmails = lazy(() => import('./components/component-email/Admin_email'));
const ListNotifications = lazy(() => import('./components/common/notifications/ListNotifications'));
const Configuraciones = lazy(() => import('./componentsConfigs/configs'));
const MisPichosOrders = lazy(() => import('./componentsReports/MisPichosOrders'));

//Marketing manager
const Landings = lazy(() => import('./componentsMarketingManager/Landings'));

//Account manager
const PetshopPays = lazy(() => import('./componentsAccountManager/PetshopPays'));
const PetshopsDetails = lazy(() => import('./componentsAccountManager/PetshopsDetails'));
const PetshopFacturacion = lazy(() => import('./componentsAccountManager/FacturacioPetshop'));
const PetshopFacturacionIngresos = lazy(() => import('./componentsAccountManager/FacturacioPetshopIngresos'));

//Components Petshop
const PetshopAcount = lazy(() => import('./componentsPetshop/PetshopAcount'));
const PetshopAccountRegistry = lazy(() => import('./componentsAccountManager/PetshopAccountRegistry'));
const PetshopRegistration = lazy(() => import('./componentsPetshop/PetshopRegistration'));
const PetshopEdit = lazy(() => import('./componentsPetshop/PetshopEdit'));
const PetshopOrders = lazy(() => import('./componentsPetshop/PetshopOrders'));
const PetshopReportStatics = lazy(() => import('./componentsPetshop/PetshopReportStatics'));
const PetshopDeliveryTime = lazy(() => import('./componentsPetshop/PetshopDeliveryTime'));
const PetshopNews = lazy(() => import('./componentsPetshop/PetshopNews'));
const PetshopPaying = lazy(() => import('./componentsPetshop/PetshopPaying'));
const PetshopZone = lazy(() => import('./componentsPetshop/PetshopZone'));
const PetshopDashboard = lazy(() => import('./views/PetshopDashboard'));
const PetshopTransporters = lazy(() => import('./componentsPetshop/Transporters'));

//Components Finances
const DashboardFinances = lazy(() => import('./componentsFinances/Dashboard'));
const FacturacionMensual = lazy(() => import('./componentsFinances/FacturacionMensual'));
const ProductosPrecios = lazy(() => import('./componentsFinances/ProductosPrecios'));
const ProductosPreciosAnalisis = lazy(() => import('./componentsFinances/ProductosPreciosAnalisis'));

//Components Reportes
const DashboardReportes = lazy(() => import('./componentsReports/Dashboard'));
const ReporteIndicadoresOferta = lazy(() => import('./componentsReports/IndicadoresOferta'));
const ReportePedidosCancelados = lazy(() => import('./componentsReports/PedidosCancelados'));
const ReporteProductosVendidos = lazy(() => import('./componentsReports/SoldProducts'));
const ReporteProductosEliminadosPosEdicion = lazy(() => import('./componentsReports/DeletedProductsPostEdition'));
const ReporteProductosVendidosDetallado = lazy(() => import('./componentsReports/SoldProductsDetail'));
const ReportePedidosIntervenidos = lazy(() => import('./componentsReports/IntervenedOrders'));
const ReporteInstallmentError = lazy(() => import('./componentsReports/InstallmentError'));
const HeatZone = lazy(() => import('./componentsReports/HeatZoneOrders'));
const ReporteProductosPuntoVentaPorVenta = lazy(() => import('./componentsReports/ProductosPuntoVentaPorVenta'));
const ReportePetshopsHorarios = lazy(() => import('./componentsReports/PetshopSchedule'));
const ReporteGeneralOrders = lazy(() => import('./componentsReports/GeneralOrders'));
const ReprogramedOrders = lazy(() => import('./componentsReports/ReprogramedOrders'));
const DelegateOrders = lazy(() => import('./componentsReports/ReporteDelegacion'));
const ProblemOrders = lazy(() => import('./componentsReports/ProblemOrders'));
const OrderCompleteManual = lazy(() => import('./componentsReports/OrdersCompletedManually'));
const UsersWithPaymentProblems = lazy(() => import('./componentsReports/UsersWithPaymentProblems'));

const PetshopCoveragePercentage = lazy(() => import('./componentsReports/PetshopCoveragePercentage'));

//Components Logistics
const CoverageArea = lazy(() => import('./componentsLogistics/CoverageArea.js'));
const Variations = lazy(() => import('./componentsLogistics/Variations'));
const LogisticaDashboard = lazy(() => import('./componentsLogisticDashboard/LogisticaDashboard'));
const ControlLogisticDashboard = lazy(() => import('./componentsControlLogistic/ControlLogisticDashboard'));
const PetshopsCoverageArea = lazy(() => import('./componentsLogistics/PetshopsCoverageArea'));
const PetshopsCapacity = lazy(() => import('./componentsLogistics/PetshopsCapacity'));
const LogisticControl = lazy(() => import('./componentsLogistics/LogisticControl/LogisticControl'));
const OrdersPreparationPanel = lazy(() => import('./componentsLogistics/PreparationOrders/OrdersPreparationPanel'));
const LogisticCapacityUsed = lazy(() => import('./componentsLogistics/LogisticCapacityUsed'));
const TransportersAssignment = lazy(() => import('./componentsLogistics/TransportersAssignment/TransportersAssignment'));

//Components Suscriptions

// :D ABM Marcas
const MarcasABM = lazy(() => import('./views/MarcasABM'));

// user cheking account
const UserChekingAccount = lazy(() => import('./componentsUserChekingAccount/UserChekingAccount'));

//Component ABM cuponen
const CouponsABM = lazy(() => import('./componentsCoupons/Dashboard'));
const ReferredABM = lazy(() => import('./componentsReferred/Dashboard'));
const AbmLanding = lazy(() => import('./componentsLandings/AbmLanding'));

// Anti fraude
const BlackList = lazy(() => import('./componentsSuspicius/BlackList'));

//Components TMS
const TMSLogin = lazy(() => import('./componentsTMS/Authentication'));

const Auth = new AuthService();

const routes = {
    publicRoutes: [
        {
            path: "/login",
            layout: LoginLayout,
            public: true,
            component: LoginPage
        },
        {
            path: "/logout",
            layout: LoginLayout,
            public: true,
            component: LogoutPage
        },
        {
            path: "/loading",
            layout: Loading,
            public: true,
            component: LoadingPage
        },
    ],
    dynamicRoutes: [
        // ********** ADMIN **********
        {
            name: "dashboard",
            layout: OrdersPinedLayout,
            component: DashboardAtencion,
            title: 'Dashboard',
            nav: true,
        },
        {
            name: "notification-new",
            layout: DefaultLayout,
            component: ListNotifications,
            title: 'Alta de Notificaciones',
            nav: true,
        },
        {
            name: "notification-list",
            layout: DefaultLayout,
            component: AdminNotificationsComponent,
            title: 'Mis Notificaciones',
            nav: true,
        },
        {
            name: "product-list",
            layout: DefaultLayout,
            title: 'Productos',
            nav: true,
            component: ProductosMP
        },
        {
            name: "product-match",
            layout: DefaultLayout,
            title: 'Matchear Productos',
            nav: false,
            component: MatchProducto
        },
        {
            name: "product-prices",
            layout: DefaultLayout,
            title: 'Fijar Precios',
            nav: true,
            component: FijarPrecios
        },
        {
            name: "user-list",
            layout: DefaultLayout,
            title: 'Usuarios Lista',
            nav: true,
            component: Usuarios
        },
        {
            name: "roles",
            layout: DefaultLayout,
            title: 'Lista Usuarios Roles',
            nav: true,
            component: ShowContent
        },
        {
            name: "order-list",
            layout: DefaultLayout,
            title: 'Lista Compras',
            nav: true,
            component: ListOrders
        },
        {
            name: "order",
            layout: OrdersPinedLayout,
            title: 'Compras',
            nav: false,
            component: Compras
        },
        {
            name: "special-days",
            layout: DefaultLayout,
            title: 'Días especiales',
            nav: true,
            component: Diasespeciales
        },
        {
            name: "configurations",
            layout: DefaultLayout,
            title: 'Configuracion',
            nav: false,
            component: Configuraciones
        },
        {
            name: "petshop-mails",
            layout: DefaultLayout,
            title: 'Reporte de Mails',
            nav: true,
            parent: 'Reportes',
            component: AdminEmails
        },
        {
            name: "mispichos-orders",
            layout: DefaultLayout,
            title: 'Pedidos en MisPichos',
            nav: true,
            parent: 'Reportes',
            component: MisPichosOrders
        },
        {
            name: "porcentaje-cobertura",
            layout: DefaultLayout,
            title: 'Reportes por zona dibujada',
            nav: true,
            parent: 'Reportes',
            component: PetshopCoveragePercentage
        },
        {
            name: "heat-zone",
            layout: DefaultLayout,
            title: 'Mapa de Calor Demanda',
            nav: true,
            parent: 'Reportes',
            component: HeatZone
        },
        {
            name: "Productos Puntoventa por venta",
            layout: DefaultLayout,
            title: 'Productos por punto de venta en base a venta',
            nav: true,
            component: ReporteProductosPuntoVentaPorVenta
        },
        // ********** ACCOUNT MANAGER **********
        {
            name: "finances",
            layout: DefaultLayout,
            title: 'Dashboard Financiero',
            nav: false,
            component: DashboardFinances
        },
        {
            name: "finances-billing",
            layout: DefaultLayout,
            title: 'Facturacion Mensual',
            nav: false,
            component: FacturacionMensual
        },
        {
            name: "finances-products",
            layout: DefaultLayout,
            title: 'Productos Precios',
            nav: false,
            component: ProductosPrecios
        },
        {
            name: "finances-products-analyze",
            layout: DefaultLayout,
            title: 'Productos Precios Analisis',
            nav: false,
            component: ProductosPreciosAnalisis
        },
        {
            name: "account-facturas-petshops",
            layout: DefaultLayout,
            title: 'Facturas Petshops Egresos',
            nav: true,
            component: PetshopFacturacion
        }, {
            name: "account-facturas-petshops",
            layout: DefaultLayout,
            title: 'Facturas Petshops Ingresos',
            nav: true,
            component: PetshopFacturacionIngresos
        },
        {
            name: "account-petshops-pays",
            layout: DefaultLayout,
            nav: true,
            parent: 'petshops',
            title: 'Petshops Transferencias',
            component: PetshopPays
        },
        {
            name: "logistic-panel-areas",
            layout: DefaultLayout,
            title: 'Zonas de entrega',
            nav: true,
            component: CoverageArea
        },
        {
            name: "logistic-panel-variations",
            layout: DefaultLayout,
            title: 'Logistica variaciones',
            nav: true,
            component: Variations
        },
        {
            name: "logistic-panel-coverage-areas",
            layout: DefaultLayout,
            title: 'Areas de Cobertura de Petshops',
            nav: true,
            component: PetshopsCoverageArea
        },
        {
            name: "logistic-panel-capacidad",
            layout: DefaultLayout,
            title: 'Capacidad logística de Petshops',
            nav: true,
            component: PetshopsCapacity
        },
        {
            name: "logistic-panel-delivery-control",
            layout: DefaultLayout,
            title: 'Control de Tráfico',
            nav: true,
            component: LogisticControl
        },
        {
            name: "logistic-panel-preparation-orders",
            layout: DefaultLayout,
            title: 'Preparación de pedidos',
            nav: true,
            component: OrdersPreparationPanel
        },
        {
            name: "logistic-panel-logistic-capacity-used",
            layout: DefaultLayout,
            title: 'Capacidad logística usada',
            nav: true,
            component: LogisticCapacityUsed
        },
        {
            name: "logistic-panel-control",
            layout: DefaultLayout,
            title: 'Control Logística',
            nav: true,
            component: ControlLogisticDashboard
        },
        {
            name: "logistic-panel-transporters-assignment",
            layout: DefaultLayout,
            title: 'Vincular Transportista con Colecta',
            nav: true,
            component: TransportersAssignment
        },
        // Reportes
        {
            name: "petshops-schedules",
            layout: DefaultLayout,
            title: 'Reporte de PetshopsxHorario',
            nav: true,
            component: ReportePetshopsHorarios
        },
        {
            name: "general-orders",
            layout: DefaultLayout,
            title: 'Reporte de Pedidos Generales',
            nav: true,
            component: ReporteGeneralOrders
        },
        {
            name: "reprogramed-orders",
            layout: DefaultLayout,
            title: 'Reporte de Pedidos Reprogramados',
            nav: true,
            component: ReprogramedOrders
        },
        {
            name: "delegate-orders",
            layout: DefaultLayout,
            title: 'Reporte de Pedidos Delegados',
            nav: true,
            component: DelegateOrders
        },
        {
            name: "problem-orders",
            layout: DefaultLayout,
            title: 'Reporte de Pedidos en Problemas',
            nav: true,
            component: ProblemOrders
        },
        {
            name: "orders-complete-manual",
            layout: DefaultLayout,
            title: 'Reporte Pedidos Completados Manual',
            nav: true,
            component: OrderCompleteManual
        },
        {
            name: "report-deleted-products-post-edition",
            layout: DefaultLayout,
            title: 'Reporte Productos Eliminados en Edición',
            nav: false,
            component: ReporteProductosEliminadosPosEdicion
        },
        {
            path: "/reportes",
            layout: DefaultLayout,
            title: 'Dashboard Reportes',
            nav: false,
            component: DashboardReportes
        },
        {
            path: "/reportes-indicadores-oferta",
            layout: DefaultLayout,
            title: 'Reporte Indicadores Oferta',
            nav: false,
            component: ReporteIndicadoresOferta
        },
        {
            path: "/reportes-productos-vendidos",
            layout: DefaultLayout,
            title: 'Reporte Productos Vendidos',
            nav: false,
            component: ReporteProductosVendidos
        },
        {
            path: "/reportes-pedidos-Cancelados",
            layout: DefaultLayout,
            title: 'Reporte Pedidos Cancelados',
            nav: false,
            component: ReportePedidosCancelados
        },
        {
            path: "/reportes-productos-vendidos-detallado",
            layout: DefaultLayout,
            title: 'Reporte Productos Vendidos Detallado',
            nav: false,
            component: ReporteProductosVendidosDetallado
        },
        {
            path: "/reportes-intervenidos",
            layout: DefaultLayout,
            title: 'Reporte Pedidos Delegados',
            nav: false,
            component: ReportePedidosIntervenidos
        },
        {
            path: "/reportes-error-cuotas",
            layout: DefaultLayout,
            title: 'Reporte Pedidos Error Cuotas',
            nav: false,
            component: ReporteInstallmentError
        },
        {
            name: "Reporte usuarios con problemas de pagos",
            layout: DefaultLayout,
            title: 'Reporte Usuarios con problemas de pago',
            nav: true,
            parent: 'Reportes',
            component: UsersWithPaymentProblems
        },
        // ---
        {
            name: "dashboard-account-manager",
            layout: DefaultLayout,
            title: 'Dashboard Petshops',
            nav: true,
            component: PetshopsDetails
        },
        {
            path: "/dashboard-tms-manager",
            layout: DefaultLayout,
            title: 'Dashboard TMS',
            nav: true,
            component: TMSLogin
        },

        {
            name: "account-petshop-registration",
            layout: DefaultLayout,
            title: 'Alta de Petshops',
            nav: true,
            parent: 'petshops',
            component: PetshopRegistration
        },
        {
            name: "account-petshop-edit",
            layout: DefaultLayout,
            title: 'Petshop Data',
            nav: false,
            parent: 'petshops',
            component: PetshopEdit
        },
        {
            name: "account-petshop-account-registry",
            layout: DefaultLayout,
            nav: true,
            parent: 'petshops',
            title: 'Petshops Estado Cuenta',
            component: PetshopAccountRegistry
        },
        // ********** CUSTOMER SERVICE **********
        {
            name: "dashboard-customer",
            layout: DefaultLayout,
            title: 'Dashboard Pedidos Solucionar',
            nav: true,
            component: DashboardAtencionRolProblema
        },
        {
            name: "customer-petshops",
            layout: DefaultLayout,
            title: 'Lista de Petshops',
            parent: 'petshops',
            nav: true,
            component: Petshops
        },
        {
            name: "suspicius",
            layout: DefaultLayout,
            title: 'Lista de bloqueos',
            parent: 'petshops',
            nav: true,
            component: BlackList
        },
        // ********** PETSHOPS **********
        {
            name: "dashboard-petshop",
            layout: Lienzo,
            component: PetshopDashboard
        },
        {
            name: "petshop-profile",
            layout: Lienzo,
            component: PetshopAcount
        },
        {
            name: "petshop-notifications",
            layout: Lienzo,
            title: 'Mis Notificaciones',
            nav: true,
            parent: 'notification',
            component: PetshopNotificationsComponent
        },
        {
            name: "petshop-orders",
            layout: Lienzo,
            component: PetshopOrders
        },
        {
            name: "petshop-products",
            layout: Lienzo,
            component: Productos
        },
        {
            name: "petshop-schedule",
            layout: Lienzo,
            component: PetshopDeliveryTime
        },
        {
            name: "petshop-zone",
            layout: Lienzo,
            component: PetshopZone,
        },
        {
            name: "petshop-special-days",
            layout: Lienzo,
            component: PetshopNews
        },
        {
            name: "petshop-paying",
            layout: Lienzo,
            component: PetshopPaying
        },
        {
            name: "petshop-report",
            layout: Lienzo,
            component: PetshopReportStatics
        },
        // ********** MARKETING **********
        {
            name: "marketing-landing",
            layout: DefaultLayout,
            component: Landings,
            title: "Landing",
            nav: true,
        },
        {
            path: "/petshop/transportes",
            layout: Lienzo,
            component: PetshopTransporters
        },
    ],
};

// Funcion Filtrar
const filterRoutes = (routes, myRoutes) => {

    console.log("FILTRAR DE: ", routes, "A: ", myRoutes)

    let finalRoutes = [];
    routes.publicRoutes.map(cada_ruta => finalRoutes.push(cada_ruta));

    //let response = await Mispichoshelper.getRoutes();
    //let myRoutes = response.payload;


    myRoutes.routes.map((myRoute, j) => {

        // MENUS
        if (!myRoute.path) {
            if (!myRoute.parent_id) {
                let objRoute = {};

                objRoute.keyParent = myRoute.name;
                objRoute.name = null;
                objRoute.layout = null;
                objRoute.component = null;
                objRoute.title = myRoute.name;
                objRoute.nav = true;

                finalRoutes.push(objRoute)
            }
        }

        // ROUTES
        else {
            routes.dynamicRoutes.map((cada_ruta, i) => {

                if (myRoute.name === cada_ruta.name) {
                    // PASA FILTRO
                    console.log("PASA RUTA FILTRO: ", myRoute.name)

                    // ROUTE
                    if (myRoute.path) {
                        let objRoute = cada_ruta;

                        objRoute.path = myRoute.path;
                        //if (!myRoute.parent_id) objRoute.keyParent = myRoute.name;
                        if (myRoute.parent_id && myRoute.parent_name) objRoute.parent = myRoute.parent_name;

                        finalRoutes.push(objRoute)
                    }

                }
                return finalRoutes;
            });

            // Default
            if (myRoute.name === myRoutes.panel) {
                let def = {
                    name: "default",
                    path: "/default",
                    exact: true,
                    layout: DefaultLayout,
                    component: () => <Redirect to={myRoute.path} />
                }
                finalRoutes.push(def)
            }
        }
        return finalRoutes;
    });

    localStorage.setItem('routes', JSON.stringify(finalRoutes));
    return finalRoutes;
}

// Funcion Filtrar
const filterRoutesMix = (routesDynamic, routes, myRoutes) => {

    let finalRoutes = [];
    routes.publicRoutes.map(cada_ruta => finalRoutes.push(cada_ruta));

    if (Auth.getToken()) {
        var profile = Auth.getProfile();

        // ROUTE STATIC
        if (profile.rol === "admin") {
            routes.routesCustomerService.map(cada_ruta => finalRoutes.push(cada_ruta));
            routes.routesAccountManager.map(cada_ruta => finalRoutes.push(cada_ruta));
            routes.routesAdmin.map(cada_ruta => finalRoutes.push(cada_ruta));
            routes.routesInsurrance.map(cada_ruta => finalRoutes.push(cada_ruta));
            routes.routesTMS.map(cada_ruta => finalRoutes.push(cada_ruta));

        }
        else if (profile.rol === "proveedor") {
            routes.routesPetshops.map(cada_ruta => finalRoutes.push(cada_ruta));
        }
        else if (profile.rol === "empresa logistica") {
            console.log('ruta  logistica terciarizada');
            routes.routesOutsourcingDeliveryEntity.map(cada_ruta => finalRoutes.push(cada_ruta));
        }
        // ROUTE DYNAMIC
        else {
            console.log("RUTA DINAMICA")
            myRoutes.routes.map((myRoute, j) => {

                // Menus
                if (!myRoute.path) {
                    if (!myRoute.parent_id) {
                        let objRoute = {};

                        objRoute.keyParent = myRoute.name;
                        objRoute.name = null;
                        objRoute.layout = null;
                        objRoute.component = null;
                        objRoute.title = myRoute.name;
                        objRoute.nav = true;

                        finalRoutes.push(objRoute)
                    }
                }

                // Routes
                else {
                    routesDynamic.dynamicRoutes.map((cada_ruta, i) => {

                        if (myRoute.name === cada_ruta.name) {
                            // Route
                            if (myRoute.path) {
                                let objRoute = cada_ruta;
                                objRoute.path = myRoute.path;
                                //if (!myRoute.parent_id) objRoute.keyParent = myRoute.name;
                                if (myRoute.parent_id && myRoute.parent_name) objRoute.parent = myRoute.parent_name;
                                finalRoutes.push(objRoute)
                            }
                        }
                        return finalRoutes;
                    });

                    // Default
                    if (myRoute.name === myRoutes.panel) {
                        let def = {
                            name: "default",
                            path: "/default",
                            exact: true,
                            layout: DefaultLayout,
                            component: () => <Redirect to={myRoute.path} />
                        }
                        finalRoutes.push(def)
                    }
                }
                return finalRoutes;
            });

        }

    }


    localStorage.removeItem('routes');
    localStorage.setItem('routes', JSON.stringify(finalRoutes));
    return finalRoutes;
}


export default routes;
export { filterRoutes, filterRoutesMix }
