import React, { useEffect, useState } from "react";
import { Modal, Tabs, List, Popover, Radio, Button, Row, Col, Divider } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import { TreeSelect } from 'antd';
import { FiMail } from "react-icons/fi";
import { FiPhone } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import Loadin from "../../../components/componentsUI/Loading";
import { AiFillStar } from "react-icons/ai";
import { CriterionTags } from "../../../functions/criterionTag";

const TabPane = Tabs.TabPane;

export default function Delegate({ compra, refrescarCompra, hideDelegar, delegarCompra }) {

    const [loading, setLoading] = useState(false);
    const [delegar, setDelegar] = useState(false);
    const [manual, setManual] = useState(false);

    // Petshops
    const [cumple, setCumple] = useState([]);
    const [cumpleProblemas, setCumpleProblemas] = useState([]);
    const [noCumpleFiltrados, setNoCumpleFilter] = useState([]);
    const [delegaron, setDelegaron] = useState([]);
    const [collectors, setCollectors] = useState([]);
    const [no_cumple, setNoCumple] = useState([]);


    const [petshopElegido, setChekPet] = useState(false);
    const [delegaMotivoId, setIdDelegate] = useState(null);
    const [observaciones, setObs] = useState("");
    const [filter, setFilter] = useState([]);
    const [showMotivos, setShowMotivos] = useState(false);
    const [automatic, setAutomatic] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const buscaDelegacionesPosibles = () => {
        Mispichoshelper.getDelegacionesPosibles({ compra_id: compra.compra_id }).then(response => {
            setLoading(false);
            setDelegaron(response.payload.con_delegaciones);
            setCumple(response.payload.cumple);
            setNoCumple(response.payload.no_cumple);
            setNoCumpleFilter(response.payload.no_cumple);
            setCumpleProblemas(response.payload.cumple_con_problemas);
            setCollectors(response.payload.collectors);
        });
    }


    const delegaManualmente = () => {
        setButtonDisabled(true)
        if (!delegaMotivoId) {
            Modal.error({
                title: "!IMPORTANTE",
                content: "No podes delegar la compra sin seleccionar un motivo",
            });
            return
        }
        setLoading(true);
        var params = {}
        if (automatic) {
            params = { observaciones: observaciones, delegaMotivoId: delegaMotivoId, 'motivo_delega': null, tipo: 'automatica', compra_id: compra.compra_id }
        } else {
            params = { observaciones: observaciones, delegaMotivoId: delegaMotivoId, 'motivo_delega': null, tipo: 'manual', compra_id: compra.compra_id, proveedor_id: petshopElegido }
        }
        Mispichoshelper.postDelegarCompra(params).then(informaResponse);
    }

    const cierraDelegar = () => {
        hideTodo();
        closeDelegate();
    }

    const informaResponse = (response) => {
        refrescarCompra(compra.compra_id);
        setLoading(false);
        setButtonDisabled(false)
        if (response.payload.status) {
            success(response);
        } else {
            error(response);
        }
    }

    const success = (response) => {
        Modal.success({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => {
                cierraDelegar()
                window.location.reload();
            },
            okText: 'Entendido'
        });
    }

    const error = (response) => {
        Modal.error({
            title: response.payload.title,
            content: response.payload.message,
            onOk: () => cierraDelegar(),
        });
    }


    const hideTodo = () => {
        setManual(false);
        setDelegar(false);
    }

    const showManual = () => {
        setManual(true);
        setDelegar(true);
        setLoading(true);

        buscaDelegacionesPosibles();
    }

    const closeDelegate = () => hideDelegar('delegarCompra', false);


    const selectFilter = (value) => {
        setFilter(value);
    }

    useEffect(() => {
        filtraResultados();
        // eslint-disable-next-line
    }, [filter])

    const filtraResultados = () => {
        console.log(filter)
        var filteredItems = filter.length <= 0 ? no_cumple : [];
        if (filter.length) {
            noCumpleFiltrados.map((cada_resultado) => {
                var cumple_condiciones = true;
                filter.map(cada_filtro => {
                    if ((!cada_resultado.puv_auto_aceptacion && (cada_filtro === 'puv_auto_aceptacion')) ||
                        (!cada_resultado.radio && (cada_filtro === 'radio')) ||
                        (!cada_resultado.productos && (cada_filtro === 'productos')) ||
                        (!cada_resultado.horarios && (cada_filtro === 'horarios')) ||
                        (!cada_resultado.tiene_capacidad && (cada_filtro === 'tiene_capacidad')) ||
                        (cada_resultado.delego && (cada_filtro === 'delego')) ||
                        (cada_resultado.problemas_con_usuario && (cada_filtro === 'problemas_con_usuario'))
                    ) {
                        cumple_condiciones = false;
                    }
                    return cumple_condiciones
                })
                if (cumple_condiciones) {
                    filteredItems.push(cada_resultado);
                }
                return filteredItems
            })
        }
        setNoCumpleFilter(filteredItems);
    }

    const showCapacityAlert = (cada_proveedor) => {
        var color = cada_proveedor.logistic_capacity_used < cada_proveedor.logistic_Limit ? 'green' : 'red';
        return (
            <span style={{ color: `${color}` }}>
                ({(cada_proveedor.logistic_capacity_used ? cada_proveedor.logistic_capacity_used : 0)} pedidos de {cada_proveedor.logistic_Limit}. Franja: {compra.compra_horario_entrega})
                <span style={{ color: 'black' }}>(Aceptación :{cada_proveedor.puv_porc_aceptacion}%)</span>
            </span>
        )
    }

    const showMasterPetStar = (cada_proveedor) => {
        if (cada_proveedor.categoria === "master-petshop") {
            return <p><AiFillStar style={{ marginBottom: "3px" }} /> MasterPetshop</p>
        }
    }
    const muestraProveedor = (proveedores, tipo) => {
        const contentTelefono = (cada_proveedor) => (
            <div>{cada_proveedor.puv_telefono ? cada_proveedor.puv_telefono : 'No tiene teléfono'}</div>
        );
    
        const contentMail = (cada_proveedor) => (
            <div>{cada_proveedor.puv_email ? cada_proveedor.puv_email : 'No tiene email'}</div>
        );
    
        return (
            <Radio.Group
                onChange={(evt) => setChekPet(parseInt(evt.target.value))}
                value={petshopElegido}
                className="w-100"
            >
                {proveedores.map((cada_proveedor,index) => (
                    <Row
                        key={cada_proveedor.puv_pro_id}
                        align="middle"
                        gutter={[16, 16]}
                        className="w-full m-1"
                    >
                        <Col span={20}>
                            <Radio
                                name="pet"
                                value={cada_proveedor.puv_pro_id}
                                className="d-flex align-items-center w-100"
                            >
                                <div>
                                    <CriterionTags data={cada_proveedor} />
                                </div>
                                <span className={cada_proveedor.delego ? 'itemTachado' : ''}>
                                    {cada_proveedor.puv_nombre + " " + (cada_proveedor.distancia / 1000).toFixed(1) + " km "}
                                    {showCapacityAlert(cada_proveedor)}
                                    {showMasterPetStar(cada_proveedor)}
                                </span>
                            </Radio>
                        </Col>
    
                        <Col span={4} className="text-end"> {/* Alineación derecha */}
                            <Popover placement="top" title="Teléfono" content={contentTelefono(cada_proveedor)}>
                                <Button className="m-1">
                                    <FiPhone className="Rota270" />
                                </Button>
                            </Popover>
                            <Popover placement="top" title="Email" content={contentMail(cada_proveedor)}>
                                <Button className="m-1">
                                    <FiMail />
                                </Button>
                            </Popover>
                            {petshopElegido === cada_proveedor.puv_pro_id && (
                                <Button className="m-1" onClick={() => setShowMotivos(true)}>
                                    <FiLogOut className="Rota270" />
                                </Button>
                            )}
                        </Col>
                        {index < proveedores.length - 1 && <Divider />}
                    </Row>
                ))}
            </Radio.Group>
        );
    };
    

    const muestraDelManual = () => {
       
        if (loading) { // Omar 9-05-19 Agrego animacion de Loading dependiendo del loading
            return <Loadin />
        }

        //const Step = Steps.Step;
        const SHOW_PARENT = TreeSelect.SHOW_PARENT;
        const treeData = [
            { title: 'Auto aceptacion', value: 'puv_auto_aceptacion', key: 'puv_auto_aceptacion', },
            { title: 'Radio', value: 'radio', key: 'radio', },
            { title: 'Productos', value: 'productos', key: 'productos', },
            { title: 'Horarios', value: 'horarios', key: 'horarios', },
            { title: 'Tiene capacidad', value: 'tiene_capacidad', key: 'tiene_capacidad', },
            { title: 'No Delego', value: 'delego', key: 'delego', },
            { title: 'Experiencia de usuario', value: 'problemas_con_usuario', key: 'problemas_con_usuario', },
        ];

        const tProps = {
            treeData,
            value: filter,
            onChange: selectFilter,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            searchPlaceholder: 'Agrega los filtros que necesites :D',
            style: {
                width: 300,
            },
        };
        return (
            <Tabs defaultActiveKey="1">
                <TabPane tab={"Cumplen (" + cumple.length + ")"} key="1">
                    <List className='mandarin'>
                        {(cumple && cumple.length > 0) ? muestraProveedor(cumple, 'cumple') : null}
                    </List>
                </TabPane>
                <TabPane tab={"Colectores (" + collectors.length + ")"} key="5">
                    <List className='mandarin'>
                        
                        {(collectors && collectors.length > 0) ? muestraProveedor(collectors, 'no_cumple') : null}
                    </List>
                </TabPane>
                <TabPane tab={"Cumplen con problemas (" + cumpleProblemas.length + ")"} key="2">
                    <br />
                    <List className='mandarin'>
                        {(cumpleProblemas && cumpleProblemas.length > 0) ? muestraProveedor(cumpleProblemas, 'cumpleProblemas'): null}
                    </List>
                </TabPane>
                <TabPane tab={"No cumple con alguna condición (" + noCumpleFiltrados.length + ")"} key="3">
                    <div className="GridDosOctavos-invert">
                        <TreeSelect {...tProps} className='w-100' />
                    </div>
                    <br />
                    <List className='mandarin'>
                        {(noCumpleFiltrados && noCumpleFiltrados.length > 0) ?  muestraProveedor(noCumpleFiltrados, 'no_cumple') : null}
                    </List>
                </TabPane>
                <TabPane tab={"Delegaron pedido (" + delegaron.length + ")"} key="4">
                    <List className='mandarin'>
                        {(delegaron && delegaron.length > 0) ?  muestraProveedor(delegaron, 'cumple') : null}
                    </List>
                </TabPane>
            </Tabs>
        )
    }

    const setFalseButton = () => {
        setAutomatic(false)
        setShowMotivos(false)
        setButtonDisabled(false)
    }

    return (
        <div>
            <Modal
                title="Delegar la compra"
                style={{ minWidth: '55vw' }}
                visible={delegarCompra}
                onOk={() => setShowMotivos(true)}
                onCancel={() => closeDelegate()}
                footer={[
                    <Button icon="left" onClick={() => closeDelegate()}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={() => setShowMotivos(true)}>
                        Delegar
                    </Button>,
                ]}
            >
                <div>
                    {!delegar &&
                        (<Row gutter={[16,16]}>
                            <Col span={12}>
                                <Button type='primary'  block onClick={() => showManual()}>
                                    Delegacion Manual
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button type='primary'  block onClick={() => setAutomatic(true)}>
                                    Delegacion Automatica
                                </Button>
                            </Col>
                        </Row>)
                    }
                    {delegar &&
                        (<Row gutter={[16,16]}>
                            <Col span={24}>
                                <Button icon='left' style={{ maxWidth: '12rem', marginBottom: '1rem' }} onClick={() => hideTodo()} >Volver</Button>
                            </Col>
                        </Row>)
                    }
                    {manual &&
                        (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                {muestraDelManual()}
                            </Col>
                        </Row>
                        )}
                </div>
            </Modal>

            <Modal
                title={automatic ? 'Confirma la delegación automática?' : 'Confirma la delegación manual?'}

                visible={automatic ? automatic : showMotivos}
                okButtonProps={{ disabled: buttonDisabled, loading: loading }}
                okText='Si, delegar'
                cancelText='Volver'
                onOk={() => delegaManualmente()}
                onCancel={() => setFalseButton()}
            >
                {!loading ?
                    <Row gutter={[16,16]}>
                        <Col span={22} offset={2}>
                            <Radio.Group>
                                <Radio inline name='motivo' value={39} onChange={(e) => setIdDelegate(parseInt(e.target.value))}>
                                    <span style={{ marginRight: 8 }}>&nbsp;Responsabilidad petshop</span>
                                </Radio>
                                <Radio inline name='motivo' value={38} onChange={(e) => setIdDelegate(parseInt(e.target.value))}>
                                    <span className="pb-5">&nbsp;Responsabilidad interna</span>
                                </Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={16} offset={2} >
                            {delegaMotivoId === 39 && <p style={{ color: 'red' }} >Esta acción implica cobro de comisión al Pet Shop</p>}
                            {delegaMotivoId === 38 && <p style={{ color: 'green' }} >Esta acción no influye en la cuenta del Pet Shop</p>}
                            <label>Comentario:</label>
                        </Col>
                        <Col xs={24} sm={24} md={16} offset={2} >
                            <textarea rows="4" cols="50" onChange={(evt) => setObs(evt.target.value)} className="campoTextarea"></textarea>
                        </Col>
                    </Row>
                    :
                    <Loadin />
                }
            </Modal>
        </div>
    );

}
